import { useCallback } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { TCreateProductMutationInput, useCreateProductMutation } from '../../../../generated/graphql';
import {
  ModalForm,
  TControlledFormComponentProps,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { ApolloError } from '@apollo/client/errors';
import { toast } from 'react-toastify';
import { generatePath, useNavigate } from 'react-router-dom';
import { GIFT_PRODUCTS_LIST_PATH } from '../../../../routes/paths';
import { getProductSchema } from '../../../../entities/products/schema';
import { getProductFormFields } from '../../../../entities/products/formFields';

export const GiftProductCreate = () => {
  const navigate = useNavigate();
  const [createProduct] = useCreateProductMutation();

  const closeModal = useCallback(() => {
    navigate(generatePath(GIFT_PRODUCTS_LIST_PATH));
  }, [navigate]);

  const onInvalidSubmit = useCallback<
    Exclude<TControlledFormProps<TCreateProductMutationInput>['onInvalidSubmit'], undefined>
  >((errors: Record<string, any>) => {
    console.error(errors);
    const submitError = errors[''];
    if (submitError) {
      toast.error(submitError.message);
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TCreateProductMutationInput>['onValidSubmit']>(
    (formData) => {
      return createProduct({
        variables: {
          input: formData,
        },
      })
        .then(() => {
          toast.success('Successfully created the product.', { autoClose: 3000 });
          closeModal();
        })
        .catch((error: ApolloError) => {
          toast.error('There was an error creating the product.');
          if (error instanceof ApolloError) {
            error.graphQLErrors.forEach((err) => toast.error(err.message));
          }
        });
    },
    [closeModal, createProduct],
  );
  return (
    <ModalForm
      headline='New Product'
      size='large'
      closeModal={closeModal}
      controlledFormProps={{
        fields: getProductFormFields() as TControlledFormComponentProps<TCreateProductMutationInput>[],
        formProps: {
          resolver: joiResolver(getProductSchema(), {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
        onInvalidSubmit: onInvalidSubmit,
      }}
    />
  );
};
