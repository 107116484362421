import {
  GetProductDocument,
  OnAnyProductChangedDocument,
  ProductFieldsFragmentDoc,
  TGetProductQuery,
  TGetProductQueryVariables,
  TListProductsQuery,
  TListProductsQueryVariables,
  TOnAnyProductChangedSubscription,
  TOnAnyProductChangedSubscriptionVariables,
  TProductFieldsFragment,
  useGetProductLazyQuery,
  useListProductsLazyQuery,
} from '../../generated/graphql';
import _ from 'lodash';
import { useEffect } from 'react';
import { subscribe, TConnectionItem } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { client } from '../../config/apollo/api';
import { onSubscriptionData } from '../../config/apollo/cache';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { serializeError } from 'serialize-error';
import { useQueryAll } from '../shared/useQueryAll';
import { useMap } from '../shared/useMap';

const queryProductOnSubscriptionData = (
  data?: TOnAnyProductChangedSubscription,
  vars?: TOnAnyProductChangedSubscriptionVariables,
) => {
  const { id, _deleted } = data?.onAnyProductChanged || {};

  if (_deleted) {
    return onSubscriptionData({ ...data } as Record<string, any>, vars);
  } else if (id) {
    client
      .query<TGetProductQuery, TGetProductQueryVariables>({
        query: GetProductDocument,
        variables: {
          id,
        },
      })
      .then((response) => {
        const productData = response?.data?.getProduct as TConnectionItem;

        if (productData) {
          const subscriptionData = {
            onAnyProductChanged: productData as TConnectionItem,
          };

          // CHANGED DATA FOR QUERIES THAT DEPEND ON NO VARIABLE (SINCE VARS SHOULD BE AN EMPTY OBJECT IN THIS SITUATION)
          onSubscriptionData(subscriptionData, vars);
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

let anyProductChangedSubscribed = false;

export const useSubscribeToProductChanged = () => {
  useEffect(() => {
    if (!anyProductChangedSubscribed) {
      subscribe({
        client,
        query: OnAnyProductChangedDocument,
        onSubscriptionData: (data, vars) => {
          return queryProductOnSubscriptionData(
            data as TOnAnyProductChangedSubscription,
            vars as TOnAnyProductChangedSubscriptionVariables,
          );
        },
        variables: {},
      });
    }

    anyProductChangedSubscribed = true;
  }, []);
};

export const useFragmentOrFetchProduct = (variables: TGetProductQueryVariables) => {
  useSubscribeToProductChanged();

  const { data, error, loading } = useFragmentOrFetch<
    TProductFieldsFragment,
    TGetProductQuery,
    TGetProductQueryVariables
  >({
    fragmentDoc: ProductFieldsFragmentDoc,
    useLazyQuery: useGetProductLazyQuery,
    variables,
    __typename: 'Product',
  });

  return {
    data,
    error: error,
    loading: loading,
  };
};

export const useQueryAllProducts = () => {
  useSubscribeToProductChanged();

  const { data, loading, error } = useQueryAll<TListProductsQuery, TListProductsQueryVariables>({
    useQuery: useListProductsLazyQuery,
    variables: {},
  });

  const products = _.compact(_.orderBy(data?.listProducts.items, ['description'], ['asc']));
  return { data: products, loading, error };
};

export const useAllProductsMap = () => {
  const { data, error, loading } = useQueryAllProducts();

  const productsMap = useMap<TProductFieldsFragment>(data);

  return {
    data: productsMap,
    error,
    loading: loading || !productsMap,
  };
};
