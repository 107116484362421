import { TControlledFormComponentProps } from '@chocolate-soup-inc/cs-frontend-components';
import { TGiftPackageType } from '../../generated/graphql';

type TGetGiftPackageFormFieldsProps = { label: string; value: string }[] | null;

export const getGiftPackageFormFields = (products?: TGetGiftPackageFormFieldsProps) => {
  const formFields: TControlledFormComponentProps[] = [
    {
      name: 'name',
      label: 'Package/Kit Name',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      type: 'select',
      label: 'Package Gift Type',
      name: 'type',
      inputOptions: {
        variant: 'outlined',
        multiple: false,
        options: [
          { label: 'New Hire', value: TGiftPackageType.NewHire },
          { label: 'Work Anniversary', value: TGiftPackageType.WorkAnniversary },
          { label: 'Employee Birthday', value: TGiftPackageType.EmployeeBirthday },
          { label: 'Spouse Birthday', value: TGiftPackageType.SpouseBirthday },
          { label: 'Child Birthday', value: TGiftPackageType.ChildBirthday },
          { label: 'Pet Birthday', value: TGiftPackageType.PetBirthday },
        ],
      },
    },
    {
      type: 'select',
      label: 'Products',
      name: 'products',
      inputOptions: {
        variant: 'outlined',
        options: products || [],
        multiple: true,
      },
    },
  ];

  return formFields;
};
