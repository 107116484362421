import { TGiftPackageType } from '../../generated/graphql';

export const getReadableGiftPackageType = (type: string) => {
  switch (type) {
    case TGiftPackageType.EmployeeBirthday:
      return 'Employee Birthday';
    case TGiftPackageType.NewHire:
      return 'New Hire';
    case TGiftPackageType.WorkAnniversary:
      return 'Work Anniversary';
    case TGiftPackageType.SpouseBirthday:
      return 'Significant Other Birthday';
    case TGiftPackageType.ChildBirthday:
      return 'Child Birthday';
    case TGiftPackageType.PetBirthday:
      return 'Pet Birthday';
    default:
      return '-';
  }
};
