import { useCallback, useMemo } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { TUpdateProductMutationInput, useUpdateProductMutation } from '../../../../generated/graphql';
import {
  ErrorPage,
  LoadingPage,
  Modal,
  ModalForm,
  TControlledFormComponentProps,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { ApolloError } from '@apollo/client/errors';
import { toast } from 'react-toastify';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { GIFT_PRODUCTS_LIST_PATH } from '../../../../routes/paths';
import { getProductDefaultValues, getProductSchema } from '../../../../entities/products/schema';
import { getProductFormFields } from '../../../../entities/products/formFields';
import { useFragmentOrFetchProduct } from '../../../../entities/products/queries';

export const GiftProductUpdate = () => {
  const navigate = useNavigate();
  const { productId } = useParams();

  const { data: product, loading, error } = useFragmentOrFetchProduct({ id: productId as string });

  const headline = useMemo(() => {
    return `Edit ${product?.description ? `"${product?.description}"` : 'Product'}`;
  }, [product]);

  const [updateProduct] = useUpdateProductMutation();

  const closeModal = useCallback(() => {
    navigate(generatePath(GIFT_PRODUCTS_LIST_PATH));
  }, [navigate]);

  const onInvalidSubmit = useCallback<
    Exclude<TControlledFormProps<TUpdateProductMutationInput>['onInvalidSubmit'], undefined>
  >((errors: Record<string, any>) => {
    console.error(errors);
    const submitError = errors[''];
    if (submitError) {
      toast.error(submitError.message);
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TUpdateProductMutationInput>['onValidSubmit']>(
    (formData) => {
      return updateProduct({
        variables: {
          id: product?.id as string,
          version: product?._version as number,
          input: formData,
        },
      })
        .then(() => {
          toast.success('Successfully updated the product.');
          closeModal();
        })
        .catch((error: ApolloError) => {
          toast.error('There was an error updating the product.');
          if (error instanceof ApolloError) {
            error.graphQLErrors.forEach((err) => toast.error(err.message));
          }
        });
    },
    [closeModal, updateProduct, product],
  );

  if (loading || !product) {
    return (
      <Modal closeModal={closeModal} headline={headline}>
        <LoadingPage />
      </Modal>
    );
  }
  if (error) {
    return (
      <Modal closeModal={closeModal} headline={headline}>
        <ErrorPage error={error} />
      </Modal>
    );
  }

  return (
    <ModalForm
      headline={headline}
      size='large'
      closeModal={closeModal}
      controlledFormProps={{
        fields: getProductFormFields() as TControlledFormComponentProps<TUpdateProductMutationInput>[],
        formProps: {
          defaultValues: getProductDefaultValues(product),
          resolver: joiResolver(getProductSchema(), {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
        onInvalidSubmit: onInvalidSubmit,
      }}
    />
  );
};
