import { JsonView, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import styles from './ChangelogHistory.module.scss';

export const stringifyValue = (value: any): string => {
  if (value == null) return 'null';
  if (typeof value === 'boolean') return value ? 'true' : 'false';
  if (typeof value !== 'object') return value as string;

  try {
    return JSON.stringify(value, null, 2);
  } catch (e) {
    console.warn('stringifyValue(): failed parsing JSON.', e);
    return value as string;
  }
};

export const ActionDetails = ({ input }: { input: any }) => {
  if (input == null || typeof input !== 'object') return <pre>{stringifyValue(input)}</pre>;
  return (
    <>
      {Object.entries(input).map(([value_key, value_value]: [string, any]) => (
        <pre key={value_key}>
          {value_key}
          {' => '}
          {value_value == null || typeof value_value !== 'object' ? (
            stringifyValue(value_value)
          ) : (
            <div className={styles.jsonContainer}>
              <JsonView data={value_value} shouldExpandNode={() => false} style={defaultStyles} />
            </div>
          )}
        </pre>
      ))}
    </>
  );
};
