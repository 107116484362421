import {
  ConfirmationModal,
  ErrorPage,
  LoadingPage,
  Modal,
  ModalForm,
  TConfirmationModalProps,
  TControlledFormComponentProps,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { DETAILS_PATH } from '../../../routes/paths';

import styles from '../CreateForm/Form.module.scss';
import { getCompanyFormFields } from '../../../entities/companies/formFields';
import { TUpdateCompanyMutationInput, useUpdateCompanyMutation } from '../../../generated/graphql';
import { joiResolver } from '@hookform/resolvers/joi';
import { getCompanyDefaultValues, getCompanySchema } from '../../../entities/companies/schema';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { useFragmentOrFetchCompany } from '../../../entities/companies/queries';
import _ from 'lodash';
import { useCSAdminCognitoUserMap } from '../../../entities/users/queries';

export const CompanyUpdateForm = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();

  const {
    data: company,
    loading,
    error,
  } = useFragmentOrFetchCompany({
    id: companyId as string,
  });

  const {
    data: adminCognitoUsersMap,
    error: adminCognitoUsersError,
    loading: adminCognitoUsersLoading,
  } = useCSAdminCognitoUserMap();

  const adminsUsersCurated = useMemo(
    () =>
      Object.values(adminCognitoUsersMap).map((admin: any) => ({
        label: `${admin.name} (${admin.email})`,
        value: `${admin.name} (${admin.email})`,
      })),
    [adminCognitoUsersMap],
  );

  const headline = useMemo(() => {
    if (company == null) {
      return 'Edit Company';
    } else {
      return `Edit ${company.name}`;
    }
  }, [company]);

  const closeModal = useCallback(() => {
    navigate(generatePath(`../${DETAILS_PATH}`));
  }, [navigate]);

  const onInvalidSubmit = useCallback<
    Exclude<TControlledFormProps<TUpdateCompanyMutationInput>['onInvalidSubmit'], undefined>
  >((errors: Record<string, any>) => {
    console.error(errors);
    const submitError = errors[''];
    if (submitError) {
      toast.error(submitError.message);
    }
  }, []);

  const [updateCompany, { loading: updateLoading }] = useUpdateCompanyMutation();

  const onConfirmSubmit = useCallback(
    (formData: TUpdateCompanyMutationInput) => {
      if (company) {
        return updateCompany({
          variables: {
            id: company.id,
            _version: company._version,
            input: formData,
          },
        })
          .then(() => {
            toast.success('Successfully updated the company.');
            closeModal();
          })
          .catch((error: ApolloError) => {
            toast.error('There was an error updating the company.');
            if (error instanceof ApolloError) {
              error.graphQLErrors.forEach((err) => toast.error(err.message));
            }
          });
      }
    },
    [closeModal, company, updateCompany],
  );

  const onSubmit = useCallback<TControlledFormProps<TUpdateCompanyMutationInput>['onValidSubmit']>(
    (formData: TUpdateCompanyMutationInput) => {
      setConfirmationProps({
        headline: 'Update Company?',
        supportingText: `Are you sure you want to update ${company.name}?`,
        confirmLabel: 'Confirm',
        onConfirmClick: () => {
          onConfirmSubmit(formData);
        },
      });
    },
    [company, onConfirmSubmit],
  );

  if (loading || !company || adminCognitoUsersLoading) {
    return (
      <Modal closeModal={closeModal} headline={headline}>
        <LoadingPage />
      </Modal>
    );
  }
  if (error || adminCognitoUsersError) {
    return (
      <Modal closeModal={closeModal} headline={headline}>
        <ErrorPage error={error} />
      </Modal>
    );
  }

  return (
    <>
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={() => setConfirmationProps(undefined)}
          confirmLoading={updateLoading}
          onCancelClick={() => setConfirmationProps(undefined)}
        />
      )}
      <ModalForm
        headline={headline}
        size='large'
        closeModal={closeModal}
        controlledFormProps={{
          className: styles.form,
          fields: getCompanyFormFields(
            company,
            adminsUsersCurated,
          ) as TControlledFormComponentProps<TUpdateCompanyMutationInput>[],
          formProps: {
            defaultValues: getCompanyDefaultValues(company),
            resolver: joiResolver(getCompanySchema(), {
              convert: true,
              abortEarly: false,
              stripUnknown: false,
            }),
            values: getCompanyDefaultValues(company),
          },
          onValidSubmit: onSubmit,
          onInvalidSubmit: onInvalidSubmit,
        }}
      />
    </>
  );
};
