import { LoadingPage, TableInner, TOnRowClick } from '@chocolate-soup-inc/cs-frontend-components';
import { TablePage } from '../../../../components/tablePage/TablePage';
import clsx from 'clsx';
import tableStyles from '../../../../components/tablePage/TablePage.module.scss';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { DETAILS_PATH, GIFT_PACKAGES_LIST_PATH, PACKAGE_PATH } from '../../../../routes/paths';
import { TGiftPackageFullType, useQueryAllGiftPackages } from '../../../../entities/giftPackages/queries';
import { getReadableGiftPackageType } from '../../../../entities/giftPackages/shared';

export const GiftPackageList = () => {
  const navigate = useNavigate();
  const { data: giftPackages, loading, error } = useQueryAllGiftPackages();

  const onRowClick: TOnRowClick<TGiftPackageFullType> = useCallback(
    (row) => {
      navigate(
        generatePath(`${GIFT_PACKAGES_LIST_PATH}/${PACKAGE_PATH}/${DETAILS_PATH}`, {
          packageId: row.original.id,
        }),
      );
    },
    [navigate],
  );
  return (
    <TablePage dataLoading={loading} error={error} title='Gift Packages'>
      {loading && <LoadingPage />}
      <TableInner<TGiftPackageFullType>
        columns={[
          {
            header: 'Name',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}>
                  <span>{cell.row.original.name}</span>
                </div>
              );
            },
          },
          {
            header: 'Type',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>{getReadableGiftPackageType(cell.row.original.type)}</span>
                </div>
              );
            },
          },
          {
            header: 'Products',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>{Object.entries(cell.row.original.products).length}</span>
                </div>
              );
            },
          },
        ]}
        data={giftPackages}
        emptyText='No packages were found.'
        onRowClick={onRowClick}
        hoverableRows={true}
        virtual={true}
      />
    </TablePage>
  );
};
