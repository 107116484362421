import { DocumentNode, InMemoryCache, TypedDocumentNode } from '@apollo/client';
import {
  connectionField,
  awsDateField,
  onSubscriptionData as onSubscriptionDataBase,
  subscribe as subscribeBase,
  TSubscriptionMapping,
  awsDateTimeField,
} from '@chocolate-soup-inc/cs-api-consumer-utils';
import {
  TUser,
  TCompany,
  ListCompaniesDocument,
  ListNotSkippedEventsWithoutGiftDocument,
  TEvent,
  ListSkippedEventsDocument,
  ListNotDoneGiftsDocument,
  ListRecentlyDoneGiftsDocument,
  ListOpenShipmentsDocument,
  ListShipmentsByAddressAndStatusDocument,
  TShipment,
  TGift,
  ListShipmentGiftsDocument,
  ListSetForPickupShipmentsDocument,
  ListUsersDocument,
  ListEmployeeAndDependatsGiftsDocument,
  ListEmployeeDependantsDocument,
  ListCompanyOfficesDocument,
  ListRosterFilesDocument,
  ListEmployeesDocument,
  ListAllOfficesDocument,
  ListDependantsDocument,
  ListCompanyEmployeesWithDependantsDocument,
  AnyCompanyChangedFieldsFragmentDoc,
  AnyOfficeChangedFieldsFragmentDoc,
  AnyDependantChangedFieldsFragmentDoc,
  AnyEventChangedFieldsFragmentDoc,
  AnyEmployeeChangedFieldsFragmentDoc,
  AnyGiftChangedFieldsFragmentDoc,
  AnyRosterFileChangedFieldsFragmentDoc,
  ListShippedShipmentsDocument,
  AnyShipmentChangedFieldsFragmentDoc,
  CompanyUserChangedFieldsFragmentDoc,
  ListAllAddressesDocument,
  AddressFieldsWithGoogleFieldsFragmentDoc,
  TEmployee,
  TAddress,
  TDependant,
  TOffice,
  TRosterFile,
  TLog,
  ListProductsDocument,
  AnyProductChangedFieldsFragmentDoc,
  TProduct,
  ListGiftPackagesDocument,
  AnyGiftPackageChangedFieldsFragmentDoc,
  TGiftPackage,
} from '../../generated/graphql';
import { client } from './api';

export const cache = new InMemoryCache({
  possibleTypes: {
    Recipient: ['Employee', 'Dependant'],
  },
  typePolicies: {
    Query: {
      queryType: true,
      fields: {
        listAddresses: connectionField<TAddress>({ keyArgs: false }),
        listCompanies: connectionField<TCompany>({ keyArgs: false }),
        listDependants: connectionField<TDependant>({ keyArgs: false }),
        listEmployees: connectionField<TEmployee>({ keyArgs: false }),
        listUsers: connectionField<TUser>({ keyArgs: ['companyId'] }),
        listUsersIncludingCSUsers: connectionField<TUser>({ keyArgs: ['companyId'] }),
        listNotSkippedEventsWithoutGift: connectionField<TEvent>({ keyArgs: false }),
        listSkippedEvents: connectionField<TEvent>({ keyArgs: false }),
        listNotDoneGifts: connectionField<TGift>({ keyArgs: false }),
        listEmployeeAndDependatsGifts: connectionField<TGift>({ keyArgs: false }),
        listRecentlyDoneGifts: connectionField<TGift>({ keyArgs: false }),
        listShipmentGifts: connectionField<TGift>({ keyArgs: false }),
        listOffices: connectionField<TOffice>({ keyArgs: false }),
        listRosterFiles: connectionField<TRosterFile>({ keyArgs: false }),
        listOpenShipments: connectionField<TShipment>({ keyArgs: false }),
        listProducts: connectionField<TProduct>({ keyArgs: false }),
        listGiftPackages: connectionField<TGiftPackage>({ keyArgs: false }),
        listShippedShipments: connectionField<TShipment>({ keyArgs: false }),
        listSetForPickupShipments: connectionField<TShipment>({ keyArgs: false }),
        listShipmentsByAddressAndStatus: connectionField<TShipment>({
          keyArgs: ['addressId', 'status'],
        }),
        listLogs: connectionField<TLog>({ keyArgs: ['companyId'] }),
      },
    },
    User: {
      keyFields: ['email'],
    },
    Company: {
      fields: {
        startDate: awsDateField(),
        endDate: awsDateField(),
      },
    },
    Dependant: {
      fields: {
        birthDate: awsDateField(),
        address: {
          read: (address: any) => {
            return address || null;
          },
        },
      },
    },
    Employee: {
      fields: {
        hireDate: awsDateField(),
        birthDate: awsDateField(),
        createdAt: awsDateTimeField(),
        updatedAt: awsDateTimeField(),
        address: {
          read: (address: any) => {
            return address || null;
          },
        },
      },
    },
    Event: {
      fields: {
        eventDate: awsDateField(),
        skippedAt: awsDateTimeField(),
      },
    },
    Gift: {
      fields: {
        eventDate: awsDateField(),
        address: {
          read: (address: any, { readField }) => {
            if (readField('addressId') == null) return null;
            return address;
          },
        },
      },
    },
    Shipment: {
      fields: {
        shippingDate: awsDateField(),
      },
    },
    Address: {
      keyFields: ['id', '_version'],
      fields: {
        _version: {
          read: (v: any) => {
            return v || '';
          },
        },
      },
    },
  },
});

const subscriptionMappings: Record<string, TSubscriptionMapping> = {
  onAnyAddressChanged: {
    listQueries: [ListAllAddressesDocument],
    fragment: AddressFieldsWithGoogleFieldsFragmentDoc,
  },
  onAnyCompanyChanged: {
    listQueries: [ListCompaniesDocument],
    fragment: AnyCompanyChangedFieldsFragmentDoc,
  },
  onAnyDependantChanged: {
    listQueries: [ListDependantsDocument, ListEmployeeDependantsDocument],
    fragment: AnyDependantChangedFieldsFragmentDoc,
  },
  onAnyEventChanged: {
    listQueries: [ListNotSkippedEventsWithoutGiftDocument, ListSkippedEventsDocument],
    fragment: AnyEventChangedFieldsFragmentDoc,
  },
  onAnyEmployeeChanged: {
    listQueries: [ListEmployeesDocument, ListCompanyEmployeesWithDependantsDocument],
    fragment: AnyEmployeeChangedFieldsFragmentDoc,
  },
  onAnyGiftChanged: {
    listQueries: [
      ListEmployeeAndDependatsGiftsDocument,
      ListNotDoneGiftsDocument,
      ListRecentlyDoneGiftsDocument,
      ListShipmentGiftsDocument,
    ],
    fragment: AnyGiftChangedFieldsFragmentDoc,
  },
  onAnyGiftPackageChanged: {
    listQueries: [ListGiftPackagesDocument],
    fragment: AnyGiftPackageChangedFieldsFragmentDoc,
  },
  onAnyOfficeChanged: {
    listQueries: [ListAllOfficesDocument, ListCompanyOfficesDocument],
    fragment: AnyOfficeChangedFieldsFragmentDoc,
  },
  onAnyProductChanged: {
    listQueries: [ListProductsDocument],
    fragment: AnyProductChangedFieldsFragmentDoc,
  },
  onAnyRosterFileChanged: {
    listQueries: [ListRosterFilesDocument],
    fragment: AnyRosterFileChangedFieldsFragmentDoc,
  },
  onAnyShipmentChanged: {
    listQueries: [
      ListOpenShipmentsDocument,
      ListShippedShipmentsDocument,
      ListSetForPickupShipmentsDocument,
      ListShipmentsByAddressAndStatusDocument,
    ],
    fragment: AnyShipmentChangedFieldsFragmentDoc,
  },
  onCompanyUserChanged: {
    listQueries: [ListUsersDocument],
    fragment: CompanyUserChangedFieldsFragmentDoc,
  },
};

export const onSubscriptionData = onSubscriptionDataBase({
  cache,
  subscriptionMappings,
});

export type TSubscribeData = {
  query: DocumentNode | TypedDocumentNode;
  variables: Record<string, any>;
};

export const subscribe = ({ query, variables }: TSubscribeData) => {
  return subscribeBase(
    {
      client,
      query,
      onSubscriptionData,
      variables,
    },
    true,
  );
};
