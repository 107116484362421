import Joi from 'joi';
import _ from 'lodash';

export const getProductSchema = () => {
  const schema = Joi.object()
    .unknown(false)
    .keys({
      id: Joi.forbidden(),
      value: Joi.number().positive().required(),
      hsCode: Joi.string()
        .custom((value, helpers) => {
          const regex = /^\d{4}\.\d{2}$/;

          if (!regex.test(value)) {
            return helpers.message({ custom: 'HS Code must be 0000.00' });
          }
          return value;
        })
        .required(),
      quantity: Joi.number().positive().required(),
      description: Joi.string().max(60).trim().required(),
      country: Joi.string().trim().required(),
    });

  return schema;
};

export const getProductDefaultValues = (product?: Record<string, any> | null) => {
  return _.pick(product || {}, ['value', 'hsCode', 'quantity', 'description', 'country']);
};
