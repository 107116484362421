import { useCallback, useMemo } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { getCompanyDefaultValues, getCompanySchema } from '../../../entities/companies/schema';
import { TCreateCompanyMutationInput, useCreateCompanyMutation } from '../../../generated/graphql';
import {
  TControlledFormProps,
  ModalForm,
  TControlledFormComponentProps,
  Modal,
  LoadingPage,
  ErrorPage,
} from '@chocolate-soup-inc/cs-frontend-components';
import styles from './Form.module.scss';
import { ApolloError } from '@apollo/client/errors';
import { toast } from 'react-toastify';
import { getCompanyFormFields } from '../../../entities/companies/formFields';
import { generatePath, useNavigate } from 'react-router-dom';
import { COMPANIES_PATH } from '../../../routes/paths';
import { useCSAdminCognitoUserMap } from '../../../entities/users/queries';

export const CreateForm = () => {
  const navigate = useNavigate();
  const [createCompany] = useCreateCompanyMutation();

  const {
    data: adminCognitoUsersMap,
    error: adminCognitoUsersError,
    loading: adminCognitoUsersLoading,
  } = useCSAdminCognitoUserMap();

  const adminsUsersCurated = useMemo(
    () =>
      Object.values(adminCognitoUsersMap).map((admin: any) => ({
        label: `${admin.name} (${admin.email})`,
        value: `${admin.name} (${admin.email})`,
      })),
    [adminCognitoUsersMap],
  );

  const closeModal = useCallback(() => {
    navigate(generatePath(COMPANIES_PATH));
  }, [navigate]);

  const onInvalidSubmit = useCallback<
    Exclude<TControlledFormProps<TCreateCompanyMutationInput>['onInvalidSubmit'], undefined>
  >((errors: Record<string, any>) => {
    console.error(errors);
    const submitError = errors[''];
    if (submitError) {
      toast.error(submitError.message);
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TCreateCompanyMutationInput>['onValidSubmit']>(
    (formData) => {
      return createCompany({
        variables: {
          input: formData,
        },
      })
        .then(() => {
          toast.success('Successfully created the company.');
          closeModal();
        })
        .catch((error: ApolloError) => {
          toast.error('There was an error creating the company.');
          if (error instanceof ApolloError) {
            error.graphQLErrors.forEach((err) => toast.error(err.message));
          }
        });
    },
    [closeModal, createCompany],
  );

  if (adminCognitoUsersLoading) {
    return (
      <Modal closeModal={closeModal} headline={'New Company'}>
        <LoadingPage />
      </Modal>
    );
  }
  if (adminCognitoUsersError) {
    return (
      <Modal closeModal={closeModal} headline={'New Company'}>
        <ErrorPage error={adminCognitoUsersError} />
      </Modal>
    );
  }

  return (
    <ModalForm
      headline='New Company'
      size='large'
      closeModal={closeModal}
      controlledFormProps={{
        className: styles.form,
        fields: getCompanyFormFields(
          undefined,
          adminsUsersCurated,
        ) as TControlledFormComponentProps<TCreateCompanyMutationInput>[],
        formProps: {
          defaultValues: getCompanyDefaultValues(),
          resolver: joiResolver(getCompanySchema(), {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
        onInvalidSubmit: onInvalidSubmit,
      }}
    />
  );
};
