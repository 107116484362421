import { CommonButton, ErrorPage, Loading, Modal, Select } from '@chocolate-soup-inc/cs-frontend-components';
import { useCallback, useMemo, useState } from 'react';
import styles from '../../giftPackConfig/GiftPackage/GiftPackageDetails/GiftPackageDetails.module.scss';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { useUpdateCompanyMutation } from '../../../generated/graphql';
import { useAllProductsMap } from '../../../entities/products/queries';
import { getReadableGiftPackageType } from '../../../entities/giftPackages/shared';

type TAddProductToPackageModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  company: { id: string; _version: number; customGiftConfiguration: Record<string, string[] | null> };
  packageKey: string;
};

export const AddProductToCustomPackageModal = ({
  isOpen,
  closeModal,
  company,
  packageKey,
}: TAddProductToPackageModalProps) => {
  const [updateCompany] = useUpdateCompanyMutation();
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>();
  const { data: productsMap, loading: productsLoading, error: productsError } = useAllProductsMap();

  const { id, _version, customGiftConfiguration } = company;

  const availableProducts = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(productsMap).filter(([key]) => !(customGiftConfiguration[packageKey] || []).includes(key)),
      ),
    [productsMap, customGiftConfiguration, packageKey],
  );

  const addProduct = useCallback(() => {
    if (!selectedProductId) return;
    setActionLoading(true);
    const updatedProducts = [...(customGiftConfiguration[packageKey] || []), selectedProductId];
    const input = {
      customGiftConfiguration: {
        ...customGiftConfiguration,
        [packageKey]: updatedProducts,
      },
    };

    updateCompany({
      variables: {
        id,
        _version,
        input,
      },
    })
      .then(() => {
        toast.success('Product added successfully.', { autoClose: 3000 });
        closeModal();
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error adding the Product to this Gift Package.');
      })
      .then(() => {
        setActionLoading(false);
      });
  }, [selectedProductId, packageKey, id, updateCompany, _version, closeModal, customGiftConfiguration]);

  if (productsLoading)
    return (
      <Modal closeModal={closeModal} headline={'Add Product to Gift Package'} open={isOpen} size='small'>
        <Loading />;
      </Modal>
    );

  if (productsError) {
    return (
      <Modal closeModal={closeModal} headline={'Add Product to Gift Package'} open={isOpen} size='small'>
        <ErrorPage error={productsError} />
      </Modal>
    );
  }

  const ModalFooter = () => {
    return (
      <>
        <CommonButton
          className={styles.addBtn}
          leadingIcon='close'
          disabled={actionLoading}
          label='Cancel'
          onClick={closeModal}
          variant='outlined'
        />
        <CommonButton
          className={styles.addBtn}
          leadingIcon='check'
          disabled={actionLoading || !selectedProductId}
          label='Add'
          onClick={addProduct}
          variant='filled'
        />
      </>
    );
  };

  return (
    <Modal
      closeModal={closeModal}
      headline={`Add Product to ${getReadableGiftPackageType(packageKey)} Gift Package`}
      open={isOpen}
      size='small'
      showCustomButton={true}
      customButton={ModalFooter}
    >
      <Select
        label='Product'
        multiple={false}
        disabled={actionLoading}
        name='product'
        onChange={(v) => {
          setSelectedProductId(v as string);
        }}
        options={Object.values(availableProducts).map((p) => ({
          label: `${p.description} [${p.quantity}u, $${p.value} CAD, ${p.hsCode}]`,
          value: p.id,
        }))}
        value={selectedProductId}
        variant='outlined'
      />
    </Modal>
  );
};
