import { InfoCardInner, Modal, TableInner } from '@chocolate-soup-inc/cs-frontend-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';
import { useFilter } from '../../contexts/filters';
import { Filters, TFiltersProps } from '../../components/filters/Filters';
import { TablePage } from '../../components/tablePage/TablePage';
import { useQueryAllCompanies } from '../../entities/companies/queries';
import { TLogFullType, TLogType, useQueryAllLogs } from '../../entities/logs/queries';
import clsx from 'clsx';
import tableStyles from '../../components/tablePage/TablePage.module.scss';
import styles from './ChangelogHistory.module.scss';
import _ from 'lodash';
import { splitLogFieldName } from '../../entities/logs/shared';
import { quickScore } from 'quick-score';
import { ActionDetails } from './ActionDetails';

export const ChangelogHistory = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [logModalInfo, setLogModalInfo] = useState<TLogType | undefined>(undefined);
  const [companyFilter, setCompanyFilter] = useState<string | undefined>(undefined);
  const [userTypeFilter, setUserTypeFilter] = useState<string | undefined>(undefined);
  const [userNameFilter, setUserNameFilter] = useState<string | undefined>(undefined);
  const [employeeNameFilter, setEmployeeNameFilter] = useState<string | undefined>(undefined);
  const [occurredFromFilter, setOccurredFromFilter] = useState<Date | undefined>(undefined);
  const [occurredToFilter, setOccurredToFilter] = useState<Date | undefined>(undefined);
  const [actionFilter, setActionFilter] = useState<string | undefined>(undefined);
  const [entityFilter, setEntityFilter] = useState<string | undefined>(undefined);

  const { data: companies } = useQueryAllCompanies();

  const companyFilters = useMemo(() => {
    return companies.filter((c) => c.isLive && c.active).map((c) => _.pick(c, ['id', 'name']));
  }, [companies]);

  const { data: logs, error, loading } = useQueryAllLogs();

  const actionFilters = useMemo(() => {
    return _.uniq(_.map(logs, 'action')).map((a) => ({ label: a as string, value: a as string }));
  }, [logs]);

  const entityFilters = useMemo(() => {
    return _.uniq(_.map(logs, 'entity')).map((e) => ({ label: e as string, value: e as string }));
  }, [logs]);

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [];

    filtersList.push({
      disabled: companyFilters.length === 0,
      includeEmptyOption: true,
      label: 'Company',
      name: 'companyId',
      onChange: (v) => {
        if (v == null) setCompanyFilter(undefined);
        else setCompanyFilter(v as string);
      },
      options: companyFilters.map((c) => ({ label: c.name, value: c.id })),
      type: 'singleSelect',
      value: companyFilter,
    });

    filtersList.push({
      includeEmptyOption: true,
      type: 'singleSelect',
      label: 'User Type',
      name: 'userType',
      onChange: (v) => {
        if (v == null) setUserTypeFilter(undefined);
        else setUserTypeFilter(v as string);
      },
      options: [
        {
          value: 'client',
          label: 'Client',
        },
        {
          value: 'internal',
          label: 'Internal',
        },
      ],
      value: userTypeFilter,
    });

    filtersList.push({
      type: 'datePicker',
      label: 'Occurred From',
      name: 'occurredFrom',
      onChange: (v) => {
        const newDate = v ? new Date(new Date(v).setHours(0, 0, 0, 0)) : undefined;
        if (newDate?.getTime() !== occurredFromFilter?.getTime()) setOccurredFromFilter(newDate);
      },
      value: occurredFromFilter,
    });

    filtersList.push({
      type: 'datePicker',
      label: 'Occurred To',
      name: 'occurredTo',
      onChange: (v) => {
        const newDate = v ? new Date(new Date(v).setHours(23, 59, 59, 0)) : undefined;
        if (newDate?.getTime() !== occurredToFilter?.getTime()) setOccurredToFilter(newDate);
      },
      value: occurredToFilter,
    });

    filtersList.push({
      type: 'textInput',
      label: 'Employee Name',
      name: 'employeeName',
      onChange: (v) => {
        if (v == null) setEmployeeNameFilter(undefined);
        else setEmployeeNameFilter(v as string);
      },
      value: employeeNameFilter,
    });

    filtersList.push({
      type: 'textInput',
      label: 'User Name',
      name: 'userName',
      onChange: (v) => {
        if (v == null) setUserNameFilter(undefined);
        else setUserNameFilter(v as string);
      },
      value: userNameFilter,
    });

    filtersList.push({
      type: 'singleSelect',
      label: 'Action',
      name: 'action',
      onChange: (v) => {
        if (v == null) setActionFilter(undefined);
        else setActionFilter(v as string);
      },
      options: actionFilters,
      value: actionFilter,
    });

    filtersList.push({
      type: 'singleSelect',
      label: 'Entity',
      name: 'entity',
      onChange: (v) => {
        if (v == null) setEntityFilter(undefined);
        else setEntityFilter(v as string);
      },
      options: entityFilters,
      value: entityFilter,
    });

    return filtersList;
  }, [
    companyFilter,
    companyFilters,
    userNameFilter,
    occurredFromFilter,
    occurredToFilter,
    employeeNameFilter,
    userTypeFilter,
    actionFilter,
    actionFilters,
    entityFilter,
    entityFilters,
  ]);

  const filteredLogs = useMemo(() => {
    return logs.filter((log: TLogFullType) => {
      if (companyFilter && log?.companyId !== companyFilter) return false;
      if (userTypeFilter && log?.userType !== userTypeFilter) return false;
      if (
        userNameFilter &&
        userNameFilter !== '' &&
        (log?.identityUserName == null || quickScore(log?.identityUserName, userNameFilter) < 0.7)
      )
        return false;
      if (
        employeeNameFilter &&
        employeeNameFilter !== '' &&
        (log?.employee?.fullName == null || quickScore(log?.employee?.fullName, employeeNameFilter) < 0.7)
      )
        return false;
      const logDate = new Date(new Date(log.occurredAt as string));
      if (occurredFromFilter && logDate <= new Date(occurredFromFilter)) return false;
      if (occurredToFilter && logDate >= new Date(occurredToFilter)) return false;
      if (actionFilter && log?.action !== actionFilter) return false;
      if (entityFilter && log?.entity !== entityFilter) return false;
      return true;
    });
  }, [
    logs,
    companyFilter,
    employeeNameFilter,
    userNameFilter,
    occurredFromFilter,
    occurredToFilter,
    userTypeFilter,
    actionFilter,
    entityFilter,
  ]);

  const companyName = useMemo(
    () => companies?.find((company) => company.id === companyFilter)?.name || '-',
    [companies, companyFilter],
  );

  const getRowId = useCallback((log: TLogType) => {
    return log.id;
  }, []);

  const onRowClick = useCallback((log: TLogType) => {
    setLogModalInfo(log);
    setIsModalOpen(true);
  }, []);

  return (
    <TablePage dataLoading={loading} error={error} title='Changelog History (Last 31 Days)'>
      <Filters filters={filters} />
      <TableInner<TLogFullType>
        data={filteredLogs}
        emptyText={logs?.length === 0 ? 'No logs were found.' : 'No logs were found with these filters.'}
        expandable={false}
        fillSelectedRows={false}
        fixedHeader={true}
        getRowId={getRowId}
        hideSubRowsSelect={true}
        hoverableRows={true}
        selectableRows={false}
        onRowClick={(row) => {
          onRowClick(row.original);
        }}
        virtual={true}
        columns={[
          {
            header: 'Company',
            cell: ({ cell }) => (
              <div className={clsx(tableStyles.tableSpaceTertiary)}>{cell.row.original.company?.name || '-'}</div>
            ),
          },
          {
            header: 'Change Date',
            cell: ({ cell }) => (
              <div className={clsx(tableStyles.tableSpaceSecondary)}>
                {format(new Date(cell.row.original.occurredAt ?? '0'), 'MM/dd/yyyy HH:mm') || '-'}
              </div>
            ),
          },
          {
            header: 'Employee Name',
            cell: ({ cell }: any) => (
              <div className={clsx(tableStyles.tableSpaceTertiary)}>{cell.row.original?.employee?.fullName || '-'}</div>
            ),
          },
          {
            header: 'User Name',
            cell: ({ cell }) => (
              <div className={clsx(tableStyles.tableSpaceTertiary)}>{cell.row.original?.identityUserName || '-'}</div>
            ),
          },
          {
            header: 'User Type',
            cell: ({ cell }) => (
              <div className={clsx(tableStyles.tableSpaceTertiary)}>{cell.row.original.userType || '-'}</div>
            ),
          },
          {
            header: 'Action',
            cell: ({ cell }) => {
              return <div className={clsx(tableStyles.tableSpaceTertiary)}>{cell.row.original.action}</div>;
            },
          },
          {
            header: 'Entity',
            cell: ({ cell }) => {
              return <div className={clsx(tableStyles.tableSpaceTertiary)}>{cell.row.original.entity}</div>;
            },
          },
        ]}
      />
      {logModalInfo && (
        <Modal
          headline={`Log (${logModalInfo?.fieldName ?? '-'}) in ${companyName}`}
          open={isModalOpen}
          size='large'
          closeModal={() => {
            setIsModalOpen(false);
          }}
        >
          <InfoCardInner<TLogFullType>
            className={styles.actionDetailsContainer}
            data={logModalInfo}
            sections={[
              {
                title: 'Information',
                columns: [
                  { label: 'Log ID', render: (data) => data?.id },
                  {
                    label: 'Change Date',
                    render: (data) => format(new Date(data?.occurredAt ?? '0'), 'do MMM, yyyy hh:mmaaa'),
                  },
                  {
                    label: 'Company',
                    render: (data) => data?.company?.name,
                  },
                  {
                    label: 'Employee Name',
                    render: (data) => data?.employee?.fullName,
                  },
                  {
                    label: 'User Name',
                    render: (data) => data?.identityUserName,
                  },
                  {
                    label: 'User Email',
                    render: (data) => data?.identityUserEmail,
                  },
                  {
                    label: 'Action',
                    render: (data) => splitLogFieldName(data?.fieldName || '').join(' '),
                  },
                  {
                    label: 'Resource ID',
                    render: (data) => data?.resourceId,
                  },
                ],
              },
            ]}
          />
          <InfoCardInner
            className={styles.actionDetailsContainer}
            data={{}}
            sections={[
              {
                title: 'Action Details (Inputted Values)',
                columns: Object.entries(logModalInfo?.variables?.input || {}).map(([key, value]: [string, any]) => {
                  return {
                    label: `${key}:`,
                    render: () => <ActionDetails input={value} />,
                  };
                }),
              },
            ]}
          />
        </Modal>
      )}
    </TablePage>
  );
};
