import Joi from 'joi';
import _ from 'lodash';
import { TGiftPackageType } from '../../generated/graphql';

export const getGiftPackageSchema = () => {
  const schema = Joi.object()
    .unknown(false)
    .keys({
      id: Joi.forbidden(),
      name: Joi.string().trim().required(),
      products: Joi.array().required(),
      type: Joi.string()
        .trim()
        .valid(...Object.values(TGiftPackageType)),
    });

  return schema;
};

export const getGiftPackagesDefaultValues = (product?: Record<string, any> | null) => {
  return _.pick(product || {}, ['name', 'products', 'type']);
};
