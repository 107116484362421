import { serializeError } from 'serialize-error';
import { onSubscriptionData } from '../../config/apollo/cache';
import {
  GetShipmentDocument,
  OnAnyEventGiftCreatedDocument,
  OnAnyShipmentChangedDocument,
  ShipmentFieldsFragmentDoc,
  TAddressParentTypes,
  TGetShipmentQuery,
  TGetShipmentQueryVariables,
  TListOpenShipmentsQuery,
  TListOpenShipmentsQueryVariables,
  TListSetForPickupShipmentsQuery,
  TListSetForPickupShipmentsQueryVariables,
  TListShipmentsByAddressAndStatusQuery,
  TListShipmentsByAddressAndStatusQueryVariables,
  TListShippedShipmentsQuery,
  TListShippedShipmentsQueryVariables,
  TOnAnyShipmentChangedSubscription,
  TOnAnyShipmentChangedSubscriptionVariables,
  TShipmentFieldsFragment,
  TShipmentStatuses,
  useGetShipmentLazyQuery,
  useListOpenShipmentsLazyQuery,
  useListSetForPickupShipmentsLazyQuery,
  useListShipmentsByAddressAndStatusLazyQuery,
  useListShippedShipmentsLazyQuery,
} from '../../generated/graphql';
import { client } from '../../config/apollo/api';
import { TConnectionItem, subscribe } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { useEffect, useMemo } from 'react';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { useAllCompaniesMap } from '../companies/queries';
import { useQueryAll } from '../shared/useQueryAll';
import _ from 'lodash';
import { useAllOfficesMap } from '../office/queries';
import { useAllAddressesMap } from '../addresses/queries';
import { useAllEmployeesMap } from '../employees/queries';
import { useAllDependantsMap } from '../dependants/queries';
import { TGiftType } from '../gifts/queries';

// SUBSCRIBE

const onShipmentSubscriptionData = (
  data?: TOnAnyShipmentChangedSubscription,
  vars?: TOnAnyShipmentChangedSubscriptionVariables,
) => {
  const { companyId, id, _deleted } = data?.onAnyShipmentChanged || {};

  if (_deleted) {
    // return onSubscriptionData(
    //   {
    //     onAnyShipmentChanged: {
    //       ...(data?.onAnyShipmentChanged || {}),
    //       address: null,
    //       gifts: null,
    //     },
    //   },
    //   vars,
    // );

    return onSubscriptionData({ ...data, address: null, gifts: null } as Record<string, any>, vars);
  } else if (companyId && id) {
    client
      .query<TGetShipmentQuery, TGetShipmentQueryVariables>({
        query: GetShipmentDocument,
        variables: {
          id,
          companyId,
        },
      })
      .then((response) => {
        const shipmentData = response?.data?.getShipment as TConnectionItem;

        if (shipmentData) {
          const subscriptionData = {
            onAnyShipmentChanged: shipmentData as TConnectionItem,
          };

          // CHANGED DATA FOR QUERIES THAT DEPEND ON NO VARIABLE (SINCE VARS SHOULD BE AN EMPTY OBJECT IN THIS SITUATION)
          onSubscriptionData(subscriptionData, vars);
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

let anyShipmetnChangedSubscribed = false;
let anyGiftRemovedFromGroupSubscribed = false;

export const useSubscribeToShipmentChanged = () => {
  useEffect(() => {
    if (!anyShipmetnChangedSubscribed) {
      subscribe({
        client,
        query: OnAnyShipmentChangedDocument,
        onSubscriptionData: (data, vars) => {
          return onShipmentSubscriptionData(
            data as TOnAnyShipmentChangedSubscription,
            vars as TOnAnyShipmentChangedSubscriptionVariables,
          );
        },
        variables: {},
      });

      anyShipmetnChangedSubscribed = true;
    }
  }, []);

  useEffect(() => {
    if (!anyGiftRemovedFromGroupSubscribed) {
      subscribe({
        client,
        query: OnAnyEventGiftCreatedDocument,
        onSubscriptionData: (data, vars) => {
          const { event } = data?.onAnyGiftRemovedFromGroup || {};

          onShipmentSubscriptionData(
            {
              onAnyShipmentChanged: event,
            },
            vars as TOnAnyShipmentChangedSubscriptionVariables,
          );
        },
        variables: {},
      });

      anyGiftRemovedFromGroupSubscribed = true;
    }
  }, []);
};

// LIST

export const useFullShipments = <T extends TShipmentFieldsFragment>(shipments: T[]) => {
  const { data: companiesMap, error: companiesError, loading: companiesLoading } = useAllCompaniesMap();

  const { data: officesMap, error: officesError, loading: officesLoading } = useAllOfficesMap();

  const { data: addressesMap, error: addressesError, loading: addressesLoading } = useAllAddressesMap();

  const { data: employeesMap, error: employeesError, loading: employeesLoading } = useAllEmployeesMap();

  const { data: dependantsMap, error: dependantsError, loading: dependantsLoading } = useAllDependantsMap();

  const fullShipments = useMemo(() => {
    return shipments.map((s) => {
      let address: (typeof addressesMap)[string] | undefined = undefined;
      if (s.addressId != null) {
        address = addressesMap[s.addressId];
      }

      let office: (typeof officesMap)[string] | undefined;

      if (address != null && address.parentType === TAddressParentTypes.Office) {
        office = officesMap[address.id.split('|')[1]];
      }

      let employee: (typeof employeesMap)[string] | undefined;

      if (address != null && address.parentType === TAddressParentTypes.Employee) {
        employee = employeesMap[address.id.split('|')[1]];
      }

      let gifts: Omit<TGiftType, 'shippingDate'>[] | undefined = undefined;

      if (s.gifts?.items != null) {
        gifts = _.compact(s.gifts.items).map((g) => {
          let recipient: (typeof employeesMap)[string] | (typeof dependantsMap)[string] | undefined;

          if (g.recipientId != null) {
            const [tableName, recipientId] = g.recipientId.split('|');

            if (tableName === process.env.REACT_APP_DEPENDANTS_TABLE_NAME) {
              recipient = dependantsMap[recipientId];
            } else if (tableName === process.env.REACT_APP_EMPLOYEES_TABLE_NAME) {
              recipient = employeesMap[recipientId];
            }
          }

          return {
            ...g,
            recipient: recipient,
            company: g.companyId ? companiesMap[g.companyId] : undefined,
            employee: g.employeeId ? employeesMap[g.employeeId] : undefined,
            address: g.addressId ? addressesMap[g.addressId] : undefined,
          };
        });
      }

      return {
        ...s,
        company: s.companyId ? companiesMap[s.companyId] : undefined,
        office: office,
        employee: employee,
        address: address,
        gifts: gifts,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressesMap, companiesMap, dependantsMap, employeesMap, officesMap, JSON.stringify(shipments)]);

  return {
    data: fullShipments,
    error: companiesError || officesError || addressesError || employeesError || dependantsError,
    loading: companiesLoading || officesLoading || addressesLoading || employeesLoading || dependantsLoading,
  };
};

export type TShipmentType<T extends TShipmentFieldsFragment = TShipmentFieldsFragment> = ReturnType<
  typeof useFullShipments<T>
>['data'][number];

export const useQueryAllOpenShipments = () => {
  useSubscribeToShipmentChanged();

  const { data, error, loading } = useQueryAll<TListOpenShipmentsQuery, TListOpenShipmentsQueryVariables>({
    useQuery: useListOpenShipmentsLazyQuery,
    variables: {},
  });

  const {
    data: fullShipments,
    error: fullError,
    loading: fullLoading,
  } = useFullShipments(_.compact(data?.listOpenShipments?.items));

  const shipments = useMemo(() => {
    return fullShipments.filter((s) => s.shippedAt == null && s.setForPickupAt == null && s.pickedUpAt == null);
  }, [fullShipments]);

  return {
    data: shipments,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useQueryAllSetForPickupShipments = () => {
  useSubscribeToShipmentChanged();

  const { data, error, loading } = useQueryAll<
    TListSetForPickupShipmentsQuery,
    TListSetForPickupShipmentsQueryVariables
  >({
    useQuery: useListSetForPickupShipmentsLazyQuery,
    variables: {},
  });

  const {
    data: fullShipments,
    error: fullError,
    loading: fullLoading,
  } = useFullShipments(_.compact(data?.listSetForPickupShipments?.items));

  const shipments = useMemo(() => {
    return fullShipments
      .filter((s) => s.setForPickupAt != null)
      .sort((a, b) => new Date(a.setForPickupAt as string).getTime() - new Date(b.setForPickupAt as string).getTime());
  }, [fullShipments]);

  return {
    data: shipments,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useQueryAllShippedShipments = () => {
  useSubscribeToShipmentChanged();

  const { data, error, loading } = useQueryAll<TListShippedShipmentsQuery, TListShippedShipmentsQueryVariables>({
    useQuery: useListShippedShipmentsLazyQuery,
    variables: {},
  });

  const {
    data: fullShipments,
    error: fullError,
    loading: fullLoading,
  } = useFullShipments(_.compact(data?.listShippedShipments?.items));

  const shipments = useMemo(() => {
    return fullShipments
      .filter((s) => s.shippedAt != null)
      .sort((a, b) => new Date(a.shippedAt as string).getTime() - new Date(b.shippedAt as string).getTime());
  }, [fullShipments]);

  return {
    data: shipments,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useQueryAllShipmentFromAddressWithoutALabel = (props: { addressId: string }) => {
  useSubscribeToShipmentChanged();

  const { addressId } = props;

  const {
    data: packagingData,
    error: packagingError,
    loading: packagingLoading,
  } = useQueryAll<TListShipmentsByAddressAndStatusQuery, TListShipmentsByAddressAndStatusQueryVariables>({
    useQuery: useListShipmentsByAddressAndStatusLazyQuery,
    // fetchPolicy: 'network-only',
    variables: {
      addressId,
      status: TShipmentStatuses.Packaging,
    },
  });

  const {
    data: readyToShipData,
    error: readyToShipError,
    loading: readyToShipLoading,
  } = useQueryAll<TListShipmentsByAddressAndStatusQuery, TListShipmentsByAddressAndStatusQueryVariables>({
    useQuery: useListShipmentsByAddressAndStatusLazyQuery,
    // fetchPolicy: 'network-only',
    variables: {
      addressId,
      status: TShipmentStatuses.ReadyToShip,
    },
  });

  const {
    data: fullShipments,
    error: fullError,
    loading: fullLoading,
  } = useFullShipments(
    _.compact(packagingData?.listShipmentsByAddressAndStatus?.items).concat(
      _.compact(readyToShipData?.listShipmentsByAddressAndStatus?.items),
    ),
  );

  return {
    data: fullShipments,
    error: packagingError || readyToShipError || fullError,
    loading: packagingLoading || readyToShipLoading || fullLoading,
  };
};

// ITEM

export const useFragmentOrFetchShipment = (variables: TGetShipmentQueryVariables) => {
  useSubscribeToShipmentChanged();

  const { data, error, loading } = useFragmentOrFetch<
    TShipmentFieldsFragment,
    TGetShipmentQuery,
    TGetShipmentQueryVariables
  >({
    useLazyQuery: useGetShipmentLazyQuery,
    variables,
    fragmentDoc: ShipmentFieldsFragmentDoc,
    __typename: 'Shipment',
  });

  const {
    data: shipments,
    error: fullError,
    loading: fullLoading,
  } = useFullShipments<Exclude<typeof data, undefined>>(_.compact([data]));

  return {
    data: shipments[0],
    error: error || fullError,
    loading: loading || fullLoading,
  };
};
