import { TControlledFormComponentProps } from '@chocolate-soup-inc/cs-frontend-components';
import styles from '../../pages/shipments/PrintLabelModal/PrintLabelModal.module.scss';

export const getProductFormFields = () => {
  const formFields: TControlledFormComponentProps[] = [
    {
      name: 'hsCode',
      label: 'HS Code',
      type: 'textField',
      inputOptions: {
        mask: ['0000{.}00'],
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'quantity',
      label: 'Quantity',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'value',
      label: 'Value',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
        trailingIcon: <span className={styles.trailingText}>CAD</span>,
      },
    },
    {
      name: 'country',
      label: 'Country of origin',
      type: 'countrySelect',
      inputOptions: {
        autoComplete: 'off',
        variant: 'outlined',
      },
    },
  ];

  return formFields;
};
