import { generatePath, useNavigate } from 'react-router-dom';
import { TCreateGiftPackageMutationInput, useCreateGiftPackageMutation } from '../../../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { GIFT_PACKAGES_LIST_PATH } from '../../../../routes/paths';
import {
  ErrorPage,
  LoadingPage,
  Modal,
  ModalForm,
  TControlledFormComponentProps,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client/errors';
import { joiResolver } from '@hookform/resolvers/joi';
import { getGiftPackageFormFields } from '../../../../entities/giftPackages/formFields';
import { getGiftPackageSchema } from '../../../../entities/giftPackages/schema';
import { useQueryAllProducts } from '../../../../entities/products/queries';

export const GiftPackageCreate = () => {
  const navigate = useNavigate();

  const { data: productsData, loading: productsLoading, error: productsError } = useQueryAllProducts();
  const [createGiftPackage] = useCreateGiftPackageMutation();

  const formProducts = useMemo(() => {
    return productsData.map((p) => ({
      label: `${p.description} [${p.quantity}u, $${p.value} CAD, ${p.hsCode}]`,
      value: p.id,
    }));
  }, [productsData]);

  const closeModal = useCallback(() => {
    navigate(generatePath(GIFT_PACKAGES_LIST_PATH));
  }, [navigate]);

  const onInvalidSubmit = useCallback<
    Exclude<TControlledFormProps<TCreateGiftPackageMutationInput>['onInvalidSubmit'], undefined>
  >((errors: Record<string, any>) => {
    console.error(errors);
    const submitError = errors[''];
    if (submitError) {
      toast.error(submitError.message);
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TCreateGiftPackageMutationInput>['onValidSubmit']>(
    (formData) => {
      return createGiftPackage({
        variables: {
          input: formData,
        },
      })
        .then(() => {
          toast.success('Successfully created the Gift Package.', { autoClose: 3000 });
          closeModal();
        })
        .catch((error: ApolloError) => {
          toast.error('There was an error creating the Gift Package.');
          if (error instanceof ApolloError) {
            error.graphQLErrors.forEach((err) => toast.error(err.message));
          }
        });
    },
    [closeModal, createGiftPackage],
  );

  if (productsLoading) {
    return (
      <Modal closeModal={closeModal} headline={`Create New Gift Package/Kit`}>
        <LoadingPage />
      </Modal>
    );
  }
  if (productsError) {
    return (
      <Modal closeModal={closeModal} headline={`Create New Gift Package/Kit`}>
        <ErrorPage error={productsError} />
      </Modal>
    );
  }

  return (
    <ModalForm
      headline={`Create New Gift Package/Kit`}
      size='large'
      closeModal={closeModal}
      controlledFormProps={{
        fields: getGiftPackageFormFields(
          formProducts,
        ) as TControlledFormComponentProps<TCreateGiftPackageMutationInput>[],
        formProps: {
          resolver: joiResolver(getGiftPackageSchema(), {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
        onInvalidSubmit: onInvalidSubmit,
      }}
    />
  );
};
