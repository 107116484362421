import {
  CommonButton,
  ErrorPage,
  IconButton,
  InfoCardInner,
  LoadingPage,
  Modal,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import { TCuratedPackageType, useFragmentOrFetchGiftPackage } from '../../../../entities/giftPackages/queries';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { GIFT_PACKAGES_LIST_PATH } from '../../../../routes/paths';
import styles from './GiftPackageDetails.module.scss';
import { getReadableGiftPackageType } from '../../../../entities/giftPackages/shared';
import { ProductModalDetails } from './ProductModalDetails';
import { AddProductToPackageModal } from './AddProductToPackageModal';
import clsx from 'clsx';

export const GiftPackageDetails = () => {
  const { packageId } = useParams();
  const navigate = useNavigate();
  const {
    data: giftPackage,
    loading: giftPackageLoading,
    error: giftPackageError,
  } = useFragmentOrFetchGiftPackage({ id: packageId as string });

  const closeModal = useCallback(() => {
    navigate(generatePath(GIFT_PACKAGES_LIST_PATH));
  }, [navigate]);

  const curatedPackage: TCuratedPackageType = useMemo(
    () => ({
      id: giftPackage?.id as string,
      _version: giftPackage?._version as number,
      products: Object.keys(giftPackage?.products || {}),
    }),
    [giftPackage],
  );

  const [addProductModalOpen, setAddProductModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);

  const closeAddProductModal = useCallback(() => {
    setAddProductModalOpen(false);
    setIsEditing(false);
  }, []);

  const topComponent = useMemo(() => {
    if (giftPackage != null) {
      return (
        <div className={styles.topComponent}>
          <div className={styles.topTitleContainer}>
            <h2 className={styles.topTitle}>{giftPackage.name ?? '-'}</h2>
            {!isEditing && (
              <div className={styles.topIcons}>
                <Tooltip message='Edit products'>
                  <IconButton disabled={false} icon={'edit'} onClick={() => setIsEditing(true)} variant='outlined' />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      );
    }
  }, [giftPackage, isEditing]);

  const bottomComponent = useCallback(() => {
    if (!giftPackage) return <></>;
    return (
      <div className={styles.topComponent}>
        <CommonButton
          className={clsx(styles.addBtn)}
          leadingIcon='close'
          label='Cancel'
          onClick={() => setIsEditing(false)}
          variant='outlined'
        />
        <CommonButton
          className={clsx(styles.addBtn)}
          leadingIcon='add'
          label='Add Product to this Gift Package'
          onClick={() => setAddProductModalOpen(true)}
          variant='filled'
        />
      </div>
    );
  }, [giftPackage]);

  if (giftPackageLoading || !giftPackage) {
    return (
      <Modal closeModal={closeModal} headline={`Details Gift Package/Kit`}>
        <LoadingPage />
      </Modal>
    );
  }

  if (giftPackageError) {
    return (
      <Modal closeModal={closeModal} headline={`Details Gift Package/Kit`}>
        <ErrorPage error={giftPackageError} />
      </Modal>
    );
  }

  return (
    <>
      <Modal
        closeModal={closeModal}
        headline={'Gift Package/Kit Details'}
        size='large'
        customButton={bottomComponent}
        showCustomButton={isEditing}
      >
        <InfoCardInner
          className={styles.infoCardInnerContainer}
          data={giftPackage}
          sections={[
            {
              topComponent: () => topComponent,
              columns: [
                {
                  label: 'Gift Package Type',
                  render: (data) => <span> {getReadableGiftPackageType(data?.type as string)}</span>,
                },
              ],
            },
          ]}
        />
        <div className={styles.productsContainer}>
          {Object.values(giftPackage?.products).map((product, index) => {
            return (
              <ProductModalDetails
                key={index}
                product={product}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                giftPackage={curatedPackage}
              />
            );
          })}
          {Object.values(giftPackage?.products).length === 0 && (
            <InfoCardInner
              data={{}}
              className={clsx(styles.emptyProductsCard)}
              sections={[
                {
                  topComponent: () => <span>There are no Products in this Gift Package</span>,
                  columns: [],
                },
              ]}
            />
          )}
        </div>
      </Modal>
      <AddProductToPackageModal
        isOpen={addProductModalOpen}
        closeModal={closeAddProductModal}
        giftPackage={curatedPackage}
      />
    </>
  );
};
