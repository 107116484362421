import {
  Chip,
  ChipMenu,
  ErrorPage,
  Expandable,
  LoadingPage,
  Modal,
  readableDate,
} from '@chocolate-soup-inc/cs-frontend-components';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { TGiftStatuses, TShipmentStatuses, useMoveGiftMutation } from '../../../generated/graphql';
import { GiftCard } from '../GiftCard/GiftCard';

import styles from './MovingToDoneModalContent.module.scss';
import { SplittingShipments } from './SplittingShipments';
import { TGroupedGiftType } from '../../../entities/gifts/queries';
import { TShipmentType, useQueryAllShipmentFromAddressWithoutALabel } from '../../../entities/shipments/queries';
import { useAddGiftsToShipment, useCreateShipmentWithGifts } from '../../../entities/gifts/mutations';

type TGroupGiftProps = {
  gift: TGroupedGiftType;
};

const GroupGift = (props: TGroupGiftProps) => {
  const { gift } = props;

  return <GiftCard gift={gift} options={{ hoverDisabled: true, overlay: true }} />;
};

const generateShipmentOption = (shipment: TShipmentType, index: number) => {
  let label = `Shipment ${index}`;

  if (shipment.shippingDate) {
    label = `${label} - Shipping Date: ${readableDate(shipment.shippingDate)}`;
  }

  return {
    label,
    value: shipment.id,
  };
};

export type TStrategies =
  | {
      type: 'create';
    }
  | {
      type: 'existing';
      id: string;
    }
  | {
      type: 'split';
      groups: string[][];
    };

type TGroupProps = {
  group: TGroupedGiftType[];
  index: number;
  onChange: (index: number, strategy: TStrategies) => void;
};

const Group = (props: TGroupProps) => {
  const { group, index, onChange } = props;

  const [selectedValue, setSelectedValue] = useState<TStrategies['type']>('create');
  const [existingShipmentId, setExistingShipmentId] = useState<string>();
  const [splitGroups, setSplitGroups] = useState<Record<string, string[]>>();

  useEffect(() => {
    if (onChange) {
      if (selectedValue === 'create') {
        return onChange(index, { type: 'create' });
      } else if (selectedValue === 'existing' && existingShipmentId != null) {
        return onChange(index, { type: 'existing', id: existingShipmentId });
      } else if (selectedValue === 'split' && splitGroups != null) {
        return onChange(index, { type: 'split', groups: Object.values(splitGroups).filter((g) => g.length > 0) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingShipmentId, index, selectedValue, splitGroups]);

  const onShipmentsChange = useCallback((groupedGifts: Record<string, string[]>) => {
    setSplitGroups(groupedGifts);
  }, []);

  const addressId = useMemo(() => {
    return group?.[0]?.addressId;
  }, [group]);

  //if (addressId == null) throw new Error('Group need an address.');

  const { data, error, loading } = useQueryAllShipmentFromAddressWithoutALabel({
    addressId: addressId || '0',
  });

  const options = useMemo(() => {
    const opts = [
      {
        label: 'New shipment',
        value: 'create',
      },
    ];

    if (data.length > 0) {
      let i = 0;
      for (const shipment of data) {
        i += 1;
        opts.push(generateShipmentOption(shipment, i));
      }
    }

    if (group.length > 1) {
      opts.push({
        label: 'Split Shipments',
        value: 'split',
      });
    }

    return opts;
  }, [data, group.length]);

  useEffect(() => {
    if (options.length < 2) {
      setSplitGroups(undefined);
      setExistingShipmentId(undefined);
      setSelectedValue('create');
    }
  }, [options.length]);

  const onSelectChange = useCallback((v?: any) => {
    if (v === 'create') {
      setExistingShipmentId(undefined);
      setSplitGroups(undefined);
      setSelectedValue('create');
    } else if (v === 'split') {
      setExistingShipmentId(undefined);
      setSelectedValue('split');
    } else {
      setSplitGroups(undefined);
      setExistingShipmentId(v);
      setSelectedValue('existing');
    }
  }, []);

  const expandableRightElement = useMemo(() => {
    let text: string | undefined;
    if (selectedValue === 'create') {
      text = 'New shipment';
    } else if (selectedValue === 'split') {
      text = 'Split into new shipments';
    } else if (selectedValue != null) {
      text = 'Add to existing shipment';
    }

    if (text) {
      if (options.length > 1) {
        return (
          <ChipMenu
            // leadingIcon='checklist'
            options={options}
            value={selectedValue === 'existing' ? existingShipmentId : selectedValue}
            onChange={onSelectChange}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        );
      } else {
        return <Chip label={text} readonly={true} selected={true} variant='suggestion' />;
      }
    }
  }, [existingShipmentId, onSelectChange, options, selectedValue]);

  return (
    <div className={styles.movingToDoneGroup}>
      <Expandable
        rightComponent={expandableRightElement}
        title={`Group ${index + 1} (${group.length} Gift${group.length === 1 ? '' : 's'})`}
      >
        {error && <ErrorPage error={error} />}
        {!error && loading && <LoadingPage />}
        {!error && !loading && (
          <div className={styles.movingToDoneGroupOuter}>
            {selectedValue === 'split' && <SplittingShipments gifts={group} onShipmentsChange={onShipmentsChange} />}
            {selectedValue !== 'split' && (
              <div className={styles.movingToDoneGroupInner}>
                {group.map((gift) => {
                  return <GroupGift key={gift.id} gift={gift} />;
                })}
              </div>
            )}
          </div>
        )}
      </Expandable>
    </div>
  );
};

type TMovingToDoneModalContentProps = {
  allGifts: TGroupedGiftType[];
  gifts: TGroupedGiftType[];
  onCancel?: () => void;
  onSuccess?: () => void;
};

type TGroupStrategy = {
  gifts: TGroupedGiftType[];
  strategy: TStrategies;
};

export const MovingToDoneModalContent = (props: TMovingToDoneModalContentProps) => {
  const { allGifts: propsAllGifts, gifts: propsMovingGifts, onCancel, onSuccess } = props;

  const [strategies, setStrategies] = useState<Record<string, TGroupStrategy>>();
  const [createShipmentsLoading, setCreateShipmentsLoading] = useState<boolean>();

  const allGifts = useMemo(() => {
    return _.uniqBy(propsAllGifts, 'id');
  }, [propsAllGifts]);

  const movingGifts = useMemo(() => {
    return _.uniqBy(propsMovingGifts, 'id');
  }, [propsMovingGifts]);

  const movingGiftsWithNoAddress = useMemo(() => {
    return movingGifts.filter((g) => g.addressId == null || g.addressId === '');
  }, [movingGifts]);

  if (movingGiftsWithNoAddress.length > 0) {
    throw new Error('Trying to move gift with no address');
  }

  const notDoneGifts = useMemo(() => {
    const movingGiftsIds = movingGifts.map((g) => g.id);
    return _.compact(allGifts || []).filter((g) => g.status !== TGiftStatuses.Done && !movingGiftsIds.includes(g.id));
  }, [allGifts, movingGifts]);

  const movingGiftsWithMissingGroupGifts = useMemo(() => {
    return movingGifts.filter((movingGift) => {
      if (movingGift.group == null) return false;

      const notDoneGroupGifts = notDoneGifts.filter((notDoneGift) => notDoneGift.group === movingGift.group);
      for (const movingGift of movingGifts) {
        if (notDoneGroupGifts.find((g) => g.group === movingGift.group) != null) {
          // "not done gift" found.
          return true;
        }
      }

      return false;
    });
  }, [movingGifts, notDoneGifts]);

  const movingGiftsWithoutMissingGroupGifts = useMemo(() => {
    return movingGifts.filter((movingGift) => {
      if (movingGift.group == null) return true;

      const notDoneGroupGifts = notDoneGifts.filter((notDoneGift) => notDoneGift.group === movingGift.group);
      for (const movingGift of movingGifts) {
        if (notDoneGroupGifts.find((g) => g.group === movingGift.group) != null) {
          // "not done gift" found.
          return false;
        }
      }

      return true;
    });
  }, [movingGifts, notDoneGifts]);

  const groupedGifts = useMemo(() => {
    const finalGifts: Record<string, TGroupedGiftType[]> = {};

    for (const [group, giftsWithNoGroup] of Object.entries(_.groupBy(movingGiftsWithoutMissingGroupGifts, 'group'))) {
      if (group == null || group === 'null' || group === 'undefined') {
        for (const [addressId, noGroupGifts] of Object.entries(_.groupBy(giftsWithNoGroup, 'addressId'))) {
          if (addressId == null || addressId === 'null' || addressId === 'undefined') {
            for (const gift of noGroupGifts) {
              finalGifts[gift.id] = [gift];
            }
          } else {
            finalGifts[addressId] = allGifts.filter((g) => noGroupGifts.map((g1) => g1.id).includes(g.id));
          }
        }
      } else {
        finalGifts[group] = allGifts.filter((g) => g.group === group);
      }
    }

    return finalGifts;
  }, [allGifts, movingGiftsWithoutMissingGroupGifts]);

  const onCancelClick = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const onChange = useCallback(
    (groupId: string, strategy: TStrategies) => {
      setStrategies((currentGroupStrategies = {}) => {
        currentGroupStrategies[groupId] = {
          gifts: groupedGifts[groupId],
          strategy,
        };
        // return currentGroupStrategies;
        return _.cloneDeep(currentGroupStrategies);
      });
    },
    [groupedGifts],
  );

  const [createShipmentWithGifts] = useCreateShipmentWithGifts();
  const [addGiftsToShipment] = useAddGiftsToShipment();
  const [moveGift] = useMoveGiftMutation();

  const onConfirmClick = useCallback(() => {
    setCreateShipmentsLoading(true);
    Promise.all([
      Promise.all(
        movingGiftsWithMissingGroupGifts.map((gift) => {
          return moveGift({
            variables: {
              id: gift.id,
              companyId: gift.companyId,
              version: gift._version,
              input: {
                status: TGiftStatuses.Done,
              },
            },
          });
        }),
      ),
      ...Object.values(strategies || {}).map(({ strategy, gifts }) => {
        if (strategy.type === 'create') {
          return createShipmentWithGifts({
            variables: {
              input: {
                companyId: gifts[0].companyId,
                addressId: gifts[0].addressId as string,
                giftsIds: gifts.map((g) => g.id),
                status: TShipmentStatuses.Packaging,
                shippingDate: gifts[0].shippingDate.toISOString().split('T')[0],
              },
            },
          });
        } else if (strategy.type === 'existing') {
          return addGiftsToShipment({
            variables: {
              shipmentId: strategy.id,
              companyId: gifts[0].companyId,
              giftsIds: gifts.map((g) => g.id),
            },
          });
        } else if (strategy.type === 'split') {
          return Promise.all(
            strategy.groups.map((group) => {
              const { addressId, companyId } = gifts.find((g) => g.id === group[0]) || {};

              if (addressId && companyId) {
                return createShipmentWithGifts({
                  variables: {
                    input: {
                      companyId,
                      addressId,
                      giftsIds: group,
                      status: TShipmentStatuses.Packaging,
                      shippingDate: gifts[0].shippingDate.toISOString().split('T')[0],
                    },
                  },
                });
              }
            }),
          );
        }
      }),
    ])
      .then(onSuccess)
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error creating the shipments.');
      })
      .then(() => {
        setCreateShipmentsLoading(false);
      });
  }, [strategies, movingGiftsWithMissingGroupGifts, onSuccess, moveGift, createShipmentWithGifts, addGiftsToShipment]);

  return (
    <Modal
      cancelLabel='Cancel'
      closeModal={onCancelClick}
      confirmLabel='Move to done'
      confirmLoading={createShipmentsLoading}
      contentClassName={styles.goingToDoneModal}
      headline='Create shipments'
      onCancelClick={onCancelClick}
      onConfirmClick={onConfirmClick}
      showCancelButton={true}
      showConfirmButton={true}
      size='large'
      supportingText='Set how you want to create the shipments for the listed gifts'
    >
      <div className={styles.movingToDoneModalContent}>
        {movingGiftsWithMissingGroupGifts && movingGiftsWithMissingGroupGifts.length > 0 && (
          <Expandable
            rightComponent={<Chip label='Not creating shipment' readonly={true} selected={true} variant='suggestion' />}
            title={`Gifts in groups with still assembling gifts - (${movingGiftsWithMissingGroupGifts.length} Gift${
              movingGiftsWithMissingGroupGifts.length === 1 ? '' : 's'
            })`}
          >
            <div className={styles.movingToDoneGroupInner}>
              {movingGiftsWithMissingGroupGifts.map((gift) => {
                return <GroupGift key={gift.id} gift={gift} />;
              })}
            </div>
          </Expandable>
        )}
        {Object.entries(groupedGifts).map(([key, group], index) => {
          return (
            <Group group={group} index={index} key={key} onChange={(_index, strategy) => onChange(key, strategy)} />
          );
        })}
      </div>
    </Modal>
  );
};
