import { CommonButton, ErrorPage, Loading, Modal, Select } from '@chocolate-soup-inc/cs-frontend-components';
import { TCuratedPackageType } from '../../../../entities/giftPackages/queries';
import { useUpdateGiftPackageMutation } from '../../../../generated/graphql';
import { useCallback, useMemo, useState } from 'react';
import { useAllProductsMap } from '../../../../entities/products/queries';
import styles from './GiftPackageDetails.module.scss';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';

type TAddProductToPackageModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  giftPackage: TCuratedPackageType;
};

export const AddProductToPackageModal = ({ isOpen, closeModal, giftPackage }: TAddProductToPackageModalProps) => {
  const [updateGiftPackage] = useUpdateGiftPackageMutation();
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>();
  const { data: productsMap, loading: productsLoading, error: productsError } = useAllProductsMap();

  const filteredProducts = useMemo(
    () => Object.fromEntries(Object.entries(productsMap).filter(([key]) => !giftPackage.products.includes(key))),
    [productsMap, giftPackage],
  );

  const addProduct = useCallback(() => {
    if (!selectedProductId) return;
    setActionLoading(true);
    const updatedProducts = [...giftPackage.products, selectedProductId];

    updateGiftPackage({
      variables: {
        id: giftPackage.id,
        version: giftPackage._version,
        input: {
          products: updatedProducts,
        },
      },
    })
      .then(() => {
        toast.success('Product added successfully.', { autoClose: 3000 });
        closeModal();
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error adding the Product to this Gift Package.');
      })
      .then(() => {
        setActionLoading(false);
      });
  }, [closeModal, updateGiftPackage, selectedProductId, giftPackage.id, giftPackage.products, giftPackage._version]);

  if (productsLoading)
    return (
      <Modal closeModal={closeModal} headline={'Add Product to Gift Package'} open={isOpen} size='small'>
        <Loading />;
      </Modal>
    );

  if (productsError) {
    return (
      <Modal closeModal={closeModal} headline={'Add Product to Gift Package'} open={isOpen} size='small'>
        <ErrorPage error={productsError} />
      </Modal>
    );
  }

  const ModalFooter = () => {
    return (
      <>
        <CommonButton
          className={styles.addBtn}
          leadingIcon='close'
          disabled={actionLoading}
          label='Cancel'
          onClick={closeModal}
          variant='outlined'
        />
        <CommonButton
          className={styles.addBtn}
          leadingIcon='check'
          disabled={actionLoading || !selectedProductId}
          label='Add'
          onClick={addProduct}
          variant='filled'
        />
      </>
    );
  };

  return (
    <Modal
      closeModal={closeModal}
      headline={'Add Product to Gift Package'}
      open={isOpen}
      size='small'
      showCustomButton={true}
      customButton={ModalFooter}
    >
      <Select
        label='Product'
        multiple={false}
        disabled={actionLoading}
        name='product'
        onChange={(v) => {
          setSelectedProductId(v as string);
        }}
        options={Object.values(filteredProducts).map((p) => ({
          label: `${p.description} [${p.quantity}u, $${p.value} CAD, ${p.hsCode}]`,
          value: p.id,
        }))}
        value={selectedProductId}
        variant='outlined'
      />
    </Modal>
  );
};
