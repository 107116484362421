import _ from 'lodash';
import { isDependant } from '../../../entities/recipients/shared';
import { TShipmentType } from '../../../entities/shipments/queries';
import { TDependantType, TGiftTypes, TWorkAnniversaryGiftVariations } from '../../../generated/graphql';
import { normalizeString } from '../../../entities/shared/utils';
import { differenceInYears } from 'date-fns';

const NEW_HIRE_KIT = [
  {
    value: 48,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
];

const SMALL_PLAN_WORK_ANNIVERSARY = [
  {
    value: 10,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
  {
    value: 10,
    hsCode: '3406.00',
    quantity: 1,
    description: 'Candle',
    country: 'CHN',
  },
  {
    value: 4,
    hsCode: '1905.31',
    quantity: 1,
    description: 'Cookies',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '1806.20',
    quantity: 1,
    description: 'Chocolates',
    country: 'CAN',
  },
  {
    value: 2,
    hsCode: '3605.00',
    quantity: 1,
    description: 'Matches',
    country: 'CHN',
  },
];

const LARGE_PLAN_WORK_ANNIVERSARY = [
  {
    value: 10,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
  {
    value: 10,
    hsCode: '3406.00',
    quantity: 1,
    description: 'Candle',
    country: 'CHN',
  },
  {
    value: 4,
    hsCode: '1905.31',
    quantity: 1,
    description: 'Cookies',
    country: 'CAN',
  },
  {
    value: 1.5,
    hsCode: '1806.20',
    quantity: 2,
    description: 'Chocolates',
    country: 'CAN',
  },
  {
    value: 2,
    hsCode: '3605.00',
    quantity: 1,
    description: 'Matches',
    country: 'CHN',
  },
  {
    value: 5,
    hsCode: '0406.30',
    quantity: 1,
    description: 'Shelf Stable Cheese',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '1905.90',
    quantity: 1,
    description: 'Crackers',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '2007.10',
    quantity: 1,
    description: 'Mini jar of Jam',
    country: 'CAN',
  },
];

const BRICK_ONLY_WORK_ANNIVERSARY = [
  {
    value: 3,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego Brick',
    country: 'DNK',
  },
];

const EMPLOYEE_BIRTHDAY = [
  {
    value: 6,
    hsCode: '8609.00',
    quantity: 1,
    description: 'Glass container',
    country: 'CHN',
  },
  {
    value: 3,
    hsCode: '1905.31',
    quantity: 1,
    description: 'Gummies',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '1005.90',
    quantity: 1,
    description: 'Pretzels',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '1005.90',
    quantity: 1,
    description: 'Candy popcorn',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '2008.19',
    quantity: 1,
    description: 'Nuts',
    country: 'CAN',
  },
];

const SPOUSE_BIRTHDAY = [
  {
    value: 5,
    hsCode: '4420.11',
    quantity: 1,
    description: 'Wood bowl',
    country: 'CHN',
  },
  {
    value: 3,
    hsCode: '1905.90',
    quantity: 1,
    description: 'Crackers',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '1005.90',
    quantity: 1,
    description: 'Candy popcorn',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '2005.20',
    quantity: 1,
    description: 'Chips',
    country: 'CAN',
  },
  {
    value: 3,
    hsCode: '2103.90',
    quantity: 1,
    description: 'Dip',
    country: 'CAN',
  },
];

const DOG_BIRTHDAY = [
  {
    value: 2,
    hsCode: '1905.31',
    quantity: 1,
    description: 'Pet cookies',
    country: 'CAN',
  },
  {
    value: 2,
    hsCode: '9506.69',
    quantity: 1,
    description: 'Pet ball',
    country: 'CHN',
  },
  {
    value: 2,
    hsCode: '4415.10',
    quantity: 1,
    description: 'Pet biodegradable bags',
    country: 'CAN',
  },
];

const CAT_BIRTHDAY = [
  {
    value: 2,
    hsCode: '1905.31',
    quantity: 1,
    description: 'Cat treat - cookies',
    country: 'CAN',
  },
  {
    value: 2,
    hsCode: '3924.90',
    quantity: 1,
    description: 'Cat toy',
    country: 'CHN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_1 = [
  {
    value: 1,
    hsCode: '4820.10',
    quantity: 1,
    description: 'Childrens notebook',
    country: 'CHN',
  },
  {
    value: 2,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Plush Toy',
    country: 'CHN',
  },
  {
    value: 0.75,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Wooden Toy Caperpillar',
    country: 'CHN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_2 = [
  {
    value: 1,
    hsCode: '4820.10',
    quantity: 1,
    description: 'Childrens notebook',
    country: 'CHN',
  },
  {
    value: 2,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Plush Toy',
    country: 'CHN',
  },
  {
    value: 3,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Wooden toy',
    country: 'CHN',
  },
  {
    value: 0.2,
    hsCode: '1806.90',
    quantity: 1,
    description: 'Candy gummies',
    country: 'CAN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_3 = [
  {
    value: 1.0,
    hsCode: '4820.10',
    quantity: 1,
    description: 'Childrens Notebook',
    country: 'CHN',
  },
  {
    value: 3.0,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Wooden game',
    country: 'CHN',
  },
  {
    value: 1.0,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Wooden Toy',
    country: 'CHN',
  },
  {
    value: 0.2,
    hsCode: '1806.90',
    quantity: 1,
    description: 'Candy gummies',
    country: 'CAN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_4 = [
  {
    value: 1.0,
    hsCode: '4820.10',
    quantity: 1,
    description: 'Childrens Notebook',
    country: 'CHN',
  },
  {
    value: 3.0,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Wooden game',
    country: 'CHN',
  },
  {
    value: 1.0,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Puzzle',
    country: 'CHN',
  },
  {
    value: 0.2,
    hsCode: '1806.90',
    quantity: 1,
    description: 'Candy gummies',
    country: 'CAN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_5 = [
  {
    value: 1.0,
    hsCode: '4820.10',
    quantity: 1,
    description: 'Childrens Notebook',
    country: 'CHN',
  },
  {
    value: 2.0,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
  {
    value: 0.5,
    hsCode: '4908.10',
    quantity: 1,
    description: 'Stickers',
    country: 'CHN',
  },
  {
    value: 0.2,
    hsCode: '1806.90',
    quantity: 1,
    description: 'Candy gummies',
    country: 'CAN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_6 = [
  {
    value: 1.0,
    hsCode: '4820.10',
    quantity: 1,
    description: 'Childrens Notebook',
    country: 'CHN',
  },
  {
    value: 2.5,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'CHN',
  },
  {
    value: 2.0,
    hsCode: '6115.95',
    quantity: 1,
    description: 'Socks',
    country: 'CHN',
  },
  {
    value: 0.5,
    hsCode: '1806.90',
    quantity: 1,
    description: 'Candy gummies',
    country: 'CAN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_7 = [
  {
    value: 1.0,
    hsCode: '4820.10',
    quantity: 1,
    description: 'Childrens Notebook',
    country: 'CHN',
  },
  {
    value: 3.0,
    hsCode: '9504.40',
    quantity: 1,
    description: 'Card game',
    country: 'CHN',
  },
  {
    value: 0.1,
    hsCode: '9609.10',
    quantity: 1,
    description: 'Pencil',
    country: 'CHN',
  },
  {
    value: 0.5,
    hsCode: '1806.90',
    quantity: 1,
    description: 'Candy gummies',
    country: 'CAN',
  },
  {
    value: 1.0,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Wooden game (tic-tac-toe)',
    country: 'CHN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_8 = [
  {
    value: 1.5,
    hsCode: '4820.10',
    quantity: 1,
    description: 'Childrens Notebook',
    country: 'CHN',
  },
  {
    value: 2.5,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
  {
    value: 0.1,
    hsCode: '9609.10',
    quantity: 1,
    description: 'Pencil',
    country: 'CHN',
  },
  {
    value: 0.5,
    hsCode: '1806.90',
    quantity: 1,
    description: 'Candy gummies',
    country: 'CAN',
  },
  {
    value: 1.0,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Wooden game (tic-tac-toe)',
    country: 'CHN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_9 = [
  {
    value: 1.5,
    hsCode: '4820.10',
    quantity: 1,
    description: 'Childrens Notebook',
    country: 'CHN',
  },
  {
    value: 2.0,
    hsCode: '6115.95',
    quantity: 1,
    description: 'Socks',
    country: 'CAN',
  },
  {
    value: 0.1,
    hsCode: '9609.10',
    quantity: 1,
    description: 'Pencil',
    country: 'CHN',
  },
  {
    value: 0.5,
    hsCode: '1806.90',
    quantity: 1,
    description: 'Candy gummies',
    country: 'CAN',
  },
  {
    value: 1.0,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Wooden game (tic-tac-toe)',
    country: 'CHN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_10_TO_14 = [
  {
    value: 1.5,
    hsCode: '4820.10',
    quantity: 1,
    description: 'Childrens Notebook',
    country: 'CHN',
  },
  {
    value: 0.5,
    hsCode: '1005.90',
    quantity: 1,
    description: 'Popcorn',
    country: 'USA',
  },
  {
    value: 2.0,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego mug',
    country: 'DNK',
  },
  {
    value: 0.1,
    hsCode: '9609.10',
    quantity: 1,
    description: 'Pencil',
    country: 'CHN',
  },
  {
    value: 3.0,
    hsCode: '9504.40',
    quantity: 1,
    description: 'Card game',
    country: 'CHN',
  },
  {
    value: 3.0,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Wooden game (tic-tac-toe)',
    country: 'CHN',
  },
];

const DEFAULT_CHILD_BIRTHDAY_15_TO_18 = [
  {
    value: 0.5,
    hsCode: '1005.90',
    quantity: 1,
    description: 'Popcorn',
    country: 'USA',
  },
  {
    value: 2.0,
    hsCode: '4202.32',
    quantity: 1,
    description: 'Felt pencil case',
    country: 'CHN',
  },
  {
    value: 2.0,
    hsCode: '4901.99',
    quantity: 1,
    description: 'Notebook',
    country: 'CHN',
  },
  {
    value: 0.5,
    hsCode: '9608.10',
    quantity: 1,
    description: 'Pen',
    country: 'CHN',
  },
  {
    value: 0.5,
    hsCode: '7117.19',
    quantity: 1,
    description: 'Pin',
    country: 'CHN',
  },
];

const customsMapping = {
  NEW_HIRE_KIT,
  SMALL_PLAN_WORK_ANNIVERSARY,
  LARGE_PLAN_WORK_ANNIVERSARY,
  BRICK_ONLY_WORK_ANNIVERSARY,
  EMPLOYEE_BIRTHDAY,
  SPOUSE_BIRTHDAY,
  DOG_BIRTHDAY,
  CAT_BIRTHDAY,
  DEFAULT_CHILD_BIRTHDAY_1,
  DEFAULT_CHILD_BIRTHDAY_2,
  DEFAULT_CHILD_BIRTHDAY_3,
  DEFAULT_CHILD_BIRTHDAY_4,
  DEFAULT_CHILD_BIRTHDAY_5,
  DEFAULT_CHILD_BIRTHDAY_6,
  DEFAULT_CHILD_BIRTHDAY_7,
  DEFAULT_CHILD_BIRTHDAY_8,
  DEFAULT_CHILD_BIRTHDAY_9,
  DEFAULT_CHILD_BIRTHDAY_10_TO_14,
  DEFAULT_CHILD_BIRTHDAY_15_TO_18,
};

export const getDefaultCustoms = (shipments: TShipmentType[]) => {
  let giftTypes: (keyof typeof customsMapping)[] = [];
  for (const shipment of shipments) {
    const company = shipment.company;
    for (const gift of shipment.gifts || []) {
      if (gift.type === TGiftTypes.NewHire) {
        giftTypes.push('NEW_HIRE_KIT');
      } else if (gift.type === TGiftTypes.WorkAnniversary) {
        if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.BrickOnly) {
          giftTypes.push('BRICK_ONLY_WORK_ANNIVERSARY');
        } else if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Deluxe) {
          giftTypes.push('LARGE_PLAN_WORK_ANNIVERSARY');
        } else if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Mini) {
          giftTypes.push('SMALL_PLAN_WORK_ANNIVERSARY');
        }
      } else if (gift.type === TGiftTypes.Birthday) {
        const recipient = gift.recipient;

        if (recipient) {
          if (isDependant(recipient)) {
            if (recipient.type === TDependantType.SignificantOther) {
              giftTypes.push('SPOUSE_BIRTHDAY');
            }
            if (recipient.type === TDependantType.Pet) {
              const petType = normalizeString(recipient.petType as string);

              switch (petType) {
                case 'dog':
                default:
                  giftTypes.push('DOG_BIRTHDAY');
                  break;
                case 'cat':
                  giftTypes.push('CAT_BIRTHDAY');
                  break;
              }
            }
            if (recipient.type === TDependantType.Child) {
              const yearsDiff = differenceInYears(new Date(), new Date(recipient.birthDate as string));

              switch (true) {
                case yearsDiff >= 1 && yearsDiff <= 9:
                  giftTypes.push(`DEFAULT_CHILD_BIRTHDAY_${yearsDiff}` as keyof typeof customsMapping);
                  break;
                case yearsDiff >= 10 && yearsDiff <= 14:
                  giftTypes.push(`DEFAULT_CHILD_BIRTHDAY_10_TO_14`);
                  break;
                case yearsDiff >= 15 && yearsDiff <= 18:
                  giftTypes.push('DEFAULT_CHILD_BIRTHDAY_15_TO_18');
                  break;
              }
            }
          } else {
            giftTypes.push('EMPLOYEE_BIRTHDAY');
          }
        }
      }
    }
  }

  giftTypes = _.uniq(giftTypes);

  if (giftTypes.length === 1) {
    return customsMapping[giftTypes[0]];
  }

  return undefined;
};
