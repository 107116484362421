import { CompanyModal } from '../CompanyModal/CompanyModal';
import { useParams } from 'react-router-dom';
import { useFragmentOrFetchCompany } from '../../../entities/companies/queries';
import {
  CommonButton,
  ConfirmationModal,
  ErrorPage,
  IconButton,
  LoadingPage,
  TableInner,
  TConfirmationModalProps,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import styles from './CompanyConfigurePackage.module.scss';
import {
  TGiftPackageType,
  TProduct,
  TProductFieldsFragment,
  useUpdateCompanyMutation,
} from '../../../generated/graphql';
import { CountryName } from '../../../components/coutryName/CountryName';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { AddProductToCustomPackageModal } from './AddProductToCustomPackageModal';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { getReadableGiftPackageType } from '../../../entities/giftPackages/shared';

export const CompanyConfigurePackage = () => {
  const [deleteConfirmationProps, setDeleteConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();
  const [actionLoading, setActionLoading] = useState<boolean>();
  const [addProductModalOpen, setAddProductModalOpen] = useState<boolean>(false);
  const [selectedPackageConfig, setSelectedPackageConfig] = useState<string>();

  const { companyId } = useParams();

  const {
    data: company,
    loading,
    error,
  } = useFragmentOrFetchCompany({
    id: companyId as string,
  });
  const [updateCompany] = useUpdateCompanyMutation();

  const closeAddProductModal = useCallback(() => {
    setAddProductModalOpen(false);
  }, []);
  const { hydratedProducts, customGiftConfiguration } = company || {};

  const onActualDelete = useCallback(
    (productId: string, packageKey: string) => {
      const curatedConfig: Record<string, string[] | null> = _.omit(customGiftConfiguration, ['__typename']);
      const updatedProducts = (curatedConfig[packageKey] ?? []).filter((p) => p !== productId);

      const input = {
        customGiftConfiguration: {
          ...curatedConfig,
          [packageKey]: updatedProducts.length ? updatedProducts : null,
        },
      };

      setActionLoading(true);

      updateCompany({
        variables: {
          id: company?.id,
          _version: company?._version,
          input,
        },
      })
        .then(() => {
          toast.success('Product removed successfully.', { autoClose: 3000 });
        })
        .catch((error) => {
          console.error(serializeError(error));
          toast.error('There was an error removing the Product from this Gift Package.');
        })
        .then(() => {
          setDeleteConfirmationProps(undefined);
          setActionLoading(false);
        });
    },
    [updateCompany, company, customGiftConfiguration],
  );

  const onDeleteClick = useCallback(
    (product: TProductFieldsFragment, packageKey: string) => {
      setDeleteConfirmationProps({
        headline: 'Remove Product from Gift Package',
        supportingText: `Are you sure you want to remove "${
          product?.description ?? '(Product removed)'
        }" from ${getReadableGiftPackageType(packageKey)} Gift Package?`,
        confirmLabel: 'Remove',
        onConfirmClick: () => onActualDelete(product.id, packageKey),
      });
    },
    [onActualDelete, setDeleteConfirmationProps],
  );

  const PackagesTable = ({ packageKey }: { packageKey: string }) => {
    return (
      <>
        <div className={clsx(styles.packageTableContainer)}>
          <div className={clsx(styles.topContainer)}>
            <h2 className={clsx(styles.tableTitle)}>{getReadableGiftPackageType(packageKey)}</h2>
            <CommonButton
              className={clsx(styles.addBtn)}
              leadingIcon='add'
              label={`Add Product`}
              onClick={() => {
                setSelectedPackageConfig(packageKey);
                setAddProductModalOpen(true);
              }}
              variant='filled'
            />
          </div>

          <TableInner<TProduct>
            className={clsx(styles.tableContainer)}
            columns={[
              {
                header: 'Description',
                cell: ({ cell }) => <>{cell.row.original?.description ?? '(Product removed)'}</>,
              },
              { header: 'HS Code', cell: ({ cell }) => <>{cell.row.original?.hsCode ?? '-'}</> },
              { header: 'Quantity', cell: ({ cell }) => <>{cell.row.original?.quantity ?? '-'}</> },
              { header: 'Value', cell: ({ cell }) => <>{cell.row.original?.value ?? '-'}</> },
              {
                header: 'Country',
                cell: ({ cell }) => <CountryName name={cell.row.original?.country || undefined} />,
              },
              {
                header: ' ',
                cell: ({ cell }) => (
                  <Tooltip message='Remove'>
                    <IconButton
                      icon='delete'
                      onClick={() => {
                        onDeleteClick(cell?.row?.original, packageKey);
                      }}
                      variant='outlined'
                    />
                  </Tooltip>
                ),
              },
            ]}
            data={hydratedProducts ? hydratedProducts[packageKey] || [] : []}
            emptyText='No products were found for this Gift Package.'
            virtual={false}
          />
        </div>
        <hr className={clsx(styles.tableSeparator)} />
      </>
    );
  };

  if (loading)
    return (
      <CompanyModal headline='Company Gift Packages Configuration'>
        <LoadingPage />
      </CompanyModal>
    );

  if (error) return <ErrorPage error={error} />;

  return (
    <>
      {!_.isEmpty(deleteConfirmationProps) && (
        <ConfirmationModal
          {...deleteConfirmationProps}
          closeModal={() => setDeleteConfirmationProps(undefined)}
          confirmLoading={actionLoading}
          onCancelClick={() => setDeleteConfirmationProps(undefined)}
        />
      )}
      <CompanyModal headline='Company Gift Packages Configuration'>
        {company?.employeeNewHireKitActivated && <PackagesTable packageKey={TGiftPackageType.NewHire} />}
        {company?.employeeWorkAnniversaryActivated && <PackagesTable packageKey={TGiftPackageType.WorkAnniversary} />}
        {company?.employeeBirthdayActivated && <PackagesTable packageKey={TGiftPackageType.EmployeeBirthday} />}
        {company?.significantOtherBirthdayActivated && <PackagesTable packageKey={TGiftPackageType.SpouseBirthday} />}
        {company?.childBirthdayActivated && <PackagesTable packageKey={TGiftPackageType.ChildBirthday} />}
        {company?.petBirthdayActivated && <PackagesTable packageKey={TGiftPackageType.PetBirthday} />}
      </CompanyModal>
      <AddProductToCustomPackageModal
        isOpen={addProductModalOpen}
        closeModal={closeAddProductModal}
        company={{
          id: company?.id,
          _version: company?._version,
          customGiftConfiguration: _.omit(company?.customGiftConfiguration, ['__typename']),
        }}
        packageKey={selectedPackageConfig as string}
      />
    </>
  );
};
