import { TProduct, TProductFieldsFragment, useUpdateGiftPackageMutation } from '../../../../generated/graphql';
import { useCallback, useMemo, useState } from 'react';
import {
  ConfirmationModal,
  IconButton,
  InfoCardInner,
  TConfirmationModalProps,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import styles from './GiftPackageDetails.module.scss';
import _ from 'lodash';
import clsx from 'clsx';
import { CountryName } from '../../../../components/coutryName/CountryName';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { TCuratedPackageType } from '../../../../entities/giftPackages/queries';

type TProductModalDetailsProps = {
  product: TProduct;
  giftPackage: TCuratedPackageType;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProductModalDetails = ({ product, giftPackage, isEditing, setIsEditing }: TProductModalDetailsProps) => {
  const [updateGiftPackage] = useUpdateGiftPackageMutation();
  const [deleteConfirmationProps, setDeleteConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();
  const [actionLoading, setActionLoading] = useState<boolean>();

  const onActualDelete = useCallback(() => {
    const updatedProducts = giftPackage.products.filter((p) => p !== product.id);

    updateGiftPackage({
      variables: {
        id: giftPackage.id,
        version: giftPackage._version,
        input: {
          products: updatedProducts,
        },
      },
    })
      .then(() => {
        toast.success('Product removed successfully.', { autoClose: 3000 });
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error removing the Product from this Gift Package.');
      })
      .then(() => {
        setDeleteConfirmationProps(undefined);
        setActionLoading(false);
        setIsEditing(false);
      });
  }, [product, giftPackage, updateGiftPackage, setIsEditing]);

  const onDeleteClick = useCallback(() => {
    setDeleteConfirmationProps({
      headline: 'Remove Product from Gift Package?',
      supportingText: `Are you sure you want to remove "${product?.description ?? '-'}"?`,
      confirmLabel: 'Remove',
      onConfirmClick: onActualDelete,
    });
  }, [onActualDelete, setDeleteConfirmationProps, product]);

  const topComponent = useMemo(() => {
    return (
      <div className={styles.topComponent}>
        <div className={styles.topTitleContainer}>
          <h4 className={styles.topTitle}>{product?.description ?? '-'}</h4>
          <div className={styles.topIcons}>
            {isEditing && (
              <Tooltip message='Remove Product from this Gift Package'>
                <IconButton disabled={false} icon='delete' onClick={onDeleteClick} variant='outlined' />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  }, [product, isEditing, onDeleteClick]);

  return (
    <>
      {!_.isEmpty(deleteConfirmationProps) && (
        <ConfirmationModal
          {...deleteConfirmationProps}
          closeModal={() => setDeleteConfirmationProps(undefined)}
          confirmLoading={actionLoading}
          onCancelClick={() => setDeleteConfirmationProps(undefined)}
        />
      )}
      <InfoCardInner
        data={product as TProductFieldsFragment}
        className={clsx(styles.productDetailsCard, styles.infoCardInnerContainer)}
        sections={[
          {
            topComponent: () => topComponent,
            columns: [
              {
                label: 'HS Code',
                render: (data) => <span>{data?.hsCode}</span>,
              },

              {
                label: 'Value',
                render: (data) => <span>{data?.value}</span>,
              },
              {
                label: 'Quantity',
                render: (data) => <span>{data?.quantity}</span>,
              },
              {
                label: 'Country',
                render: (data) => <CountryName name={data?.country} />,
              },
            ],
          },
        ]}
      />
    </>
  );
};
